import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { MouseEventHandler, SVGProps } from 'react'
import { ButtonLoader } from './icons/ButtonLoader'
import { Tooltip } from './Tooltip'

const styles = create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: {
      default: 'pointer',
      ':disabled': 'default',
    },
    border: 'none',
    width: spacing.xxl,
    height: spacing.xxl,
    borderRadius: spacing.xxSmall,
    backgroundColor: 'transparent',
    color: {
      default: color.iconButtonColorDefault,
      ':hover:enabled': color.iconButtonColorHover,
      ':active': color.iconButtonColorActive,
      ':focus': color.iconButtonColorFocus,
      ':disabled': color.iconButtonColorDisabled,
    },
    outline: 'none',
    pointerEvents: {
      ':disabled': 'none',
    },
  },
  secondary: {
    backgroundColor: {
      default: color.buttonBackgroundDefault,
      ':hover:enabled': color.buttonBackgroundHover,
    },
  },
  transparent: {
    backgroundColor: {
      default: color.buttonBackgroundDefault,
      ':hover:enabled': color.buttonBackgroundHover,
    },
    opacity: '0.5',
  },
  dark: {
    backgroundColor: {
      default: color.codGrayLight,
      ':hover:enabled': color.codGray,
    },
  },
  large: {
    width: spacing.large,
    height: spacing.large,
  },
  extraLarge: {
    width: spacing.extraLarger,
    height: spacing.extraLarger,
  },
  loader: {
    width: '1.125rem',
    height: '1.125rem',
    animation: 'spin 1s linear infinite',
  },
  white: {
    color: {
      default: color.white,
      ':hover:enabled': color.gold,
    },
  },
  gold: {
    color: color.gold,
  },
  rounded: {
    borderRadius: '50%',
  },
})

type IconButtonProps = {
  variant?: 'large' | 'extraLarge'
  color?: 'white' | 'gold'
  background?: 'secondary' | 'dark' | 'transparent'
  rounded?: boolean
  disabled?: boolean
  isLoading?: boolean
  tooltip?: string
  icon: React.FC<SVGProps<SVGElement>>
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const IconButton: React.FC<IconButtonProps> = ({
  variant,
  rounded,
  disabled,
  isLoading,
  tooltip,
  icon: Icon,
  color,
  background: backgroundVariant,
  onClick,
}) => {
  if (isLoading) {
    return (
      <button
        disabled
        {...props(
          styles.container,
          variant && styles[variant],
          backgroundVariant && styles[backgroundVariant]
        )}
      >
        <div {...props(styles.loader)}>
          <ButtonLoader />
        </div>
      </button>
    )
  }

  const buttonContent = (
    <button
      {...props(
        styles.container,
        variant && styles[variant],
        color && styles[color],
        backgroundVariant && styles[backgroundVariant],
        rounded && styles.rounded
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon />
    </button>
  )

  if (tooltip) {
    return <Tooltip text={tooltip}>{buttonContent}</Tooltip>
  }

  return buttonContent
}
