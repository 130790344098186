import { create, props } from '@stylexjs/stylex'
import { GetChannelInfoResponse } from '../../../client'
import { Button } from '../../../components/Button'
import useLanguage from '../../../translations/useLanguage'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  editorButton: {
    ':not(#_id) button': {
      width: {
        default: '100%',
        [largeScreen]: 'fit-content',
      },
    },
  },
})

type EditButtonProps = {
  channel: GetChannelInfoResponse
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

const EditButton: React.FC<EditButtonProps> = ({
  channel,
  isEditing,
  setIsEditing,
}) => {
  const { t } = useLanguage()

  if (!channel.isChannelOwner) return null

  return (
    <div {...props(styles.editorButton)}>
      <Button
        onClick={() => setIsEditing(!isEditing)}
        variant="cta"
        type="button"
      >
        {isEditing
          ? t('myChannel.promoPanel.exitEditingButtonLabel')
          : t('myChannel.promoPanel.editPanelsButtonLabel')}
      </Button>
    </div>
  )
}

export default EditButton
