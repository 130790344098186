import { create, props } from '@stylexjs/stylex'
import useLanguage from '../translations/useLanguage'
import { GetChannelLoggedInUserResponse } from '../client'
import { color, fontSize } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'

const styles = create({
  expirationText: {
    fontSize: fontSize.small,
    color: color.white,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
  expirationLabel: {
    color: color.dustyGray,
  },
})

type SubscriptionExpirationProps = {
  me?: GetChannelLoggedInUserResponse
}

export const SubscriptionExpiration: React.FC<SubscriptionExpirationProps> = ({
  me,
}) => {
  const { formatDate, t } = useLanguage()

  if (!me?.subscription?.expiresAt || me.subscription.isFree) return null

  return (
    <div {...props(styles.expirationText, fonts.extraSmallRegular)}>
      <span {...props(styles.expirationLabel)}>
        {t('subscription.expiresLabel')}
      </span>
      {formatDate(me.subscription.expiresAt)}
    </div>
  )
}
