import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { routes } from './routes'
import { ErrorLayout } from '../layout/ErrorLayout'
import { RootLayout } from '../layout/RootLayout'
import { ProtectedRoute } from './ProtectedRoute'
import { MainLayout } from '../layout/MainLayout'
import { HomePageLayout } from '../layout/HomePageLayout'
import { PageLayout } from '../layout/PageLayout'
import NotFoundPage from '../layout/pages/NotFoundPage'
import CallbackPage from '../auth/CallbackPage'
import SignUpPage from '../auth/SignUpPage'
import LogoutPage from '../auth/LogoutPage'
import LoginPage from '../auth/LoginPage'
import FeedsPage from '../post/FeedsPage'
import StreamsHomePage from '../home/StreamsHomePage'
import { CreatorRoute } from './CreatorRoute'
import { NotCreatorRoute } from './NotCreatorRoute'
import { ChannelPage } from '../channel/ChannelPage'
import { ChannelAboutPage } from '../channel/tabs/about/ChannelAboutPage'
import { ChannelPostsPage } from '../channel/tabs/posts/ChannelPostsPage'
import { SingleChannelPage } from '../channel/SingleChannelPage'
import { CreatePostPage } from '../post/CreatePostPage'

// Note: Lazy load pages to improve performance, check vite solution
const LazyPages = {
  MyProfile: lazy(() => import('../profile/MyProfile')),
  ProfileMenu: lazy(() => import('../layout/menu/ProfileMenu')),
  StreamKey: lazy(() => import('../stream/StreamKeyPage')),
  SubscriptionPricePage: lazy(
    () => import('../subscription/subscription-price/SubscriptionPricePage')
  ),
  StyleGuidePage: lazy(() => import('../style-guide/pages/StyleGuidePage')),
  StoryblokStatic: lazy(() => import('../story-blok/StoryblokStaticPage')),
  BecomeACreator: lazy(() => import('../become-a-creator/BecomeACreator')),
  PaymentHistory: lazy(() => import('../payment-history/PaymentHistoryPage')),
  Statements: lazy(() => import('../statements/StatementsPage')),
  Subscriptions: lazy(
    () => import('../subscription/subscriptions/SubscriptionsPage')
  ),
}

export const Router = () => {
  return (
    <Routes>
      {/* Main layout */}
      <Route element={<RootLayout />}>
        <Route element={<MainLayout />}>
          {/* Public Routes */}
          <Route element={<PageLayout />}>
            <Route element={<HomePageLayout />}>
              <Route path={routes.home} element={<FeedsPage />} />
              <Route path={routes.streamsHome} element={<StreamsHomePage />} />
            </Route>
          </Route>

          <Route path={routes.auth.callback} element={<CallbackPage />} />

          <Route element={<ChannelPage />}>
            <Route element={<ChannelAboutPage />}>
              <Route path={routes.channel.homeFull(':slug')} />
              <Route path={routes.channel.homeShort(':slug')} />
            </Route>
            <Route element={<ChannelPostsPage />}>
              <Route path={routes.channel.postsFull(':slug')} />
              <Route path={routes.channel.postsShort(':slug')} />
            </Route>
          </Route>
          <Route element={<SingleChannelPage />}>
            <Route path={routes.channel.postByIdFull(':postId', ':slug')} />
            <Route path={routes.channel.postByIdShort(':postId', ':slug')} />
          </Route>
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route
              path={routes.profileRoutes.myProfile}
              element={<LazyPages.MyProfile />}
            />
            <Route
              path={routes.profileRoutes.paymentHistory}
              element={<LazyPages.PaymentHistory />}
            />
            <Route
              path={routes.profileRoutes.subscriptions}
              element={<LazyPages.Subscriptions />}
            />
            {/* Creator Routes */}
            <Route element={<CreatorRoute />}>
              <Route
                path={routes.profileRoutes.streamKey}
                element={<LazyPages.StreamKey />}
              />
              <Route
                path={routes.profileRoutes.subscriptionPrice}
                element={<LazyPages.SubscriptionPricePage />}
              />
              <Route
                path={routes.profileRoutes.statements}
                element={<LazyPages.Statements />}
              />
              <Route path={routes.post.create} element={<CreatePostPage />} />
            </Route>
            {/* Not a Creator Routes */}
            <Route element={<NotCreatorRoute />}>
              <Route
                path={routes.becomeACreator}
                element={<LazyPages.BecomeACreator />}
              />
            </Route>
          </Route>

          {/* Storyblok static pages */}
          <Route element={<LazyPages.StoryblokStatic />}>
            <Route path={routes.communityGuidelines} />
            <Route path={routes.termsOfService} />
            <Route path={routes.dmcaPolicy} />
            <Route path={routes.privacyPolicy} />
            <Route path={routes.howToEmbed} />
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path={routes.profile} element={<LazyPages.ProfileMenu />} />
        </Route>
      </Route>

      <Route element={<RootLayout />}>
        <Route
          path={routes.styleGuide}
          element={<LazyPages.StyleGuidePage />}
        />
      </Route>

      {/* Empty layout */}
      <Route path={routes.signup} element={<SignUpPage />} />
      <Route path={routes.logout} element={<LogoutPage />} />
      <Route path={routes.login} element={<LoginPage />} />

      {/* Error layout */}
      <Route element={<ErrorLayout />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={routes.notFound} element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
