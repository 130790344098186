import type { SVGProps } from 'react'

export const Currency: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 512 512"
    height={height}
    width={width}
    fill={fill}
  >
    <path d="M256,0C114.848,0,0,114.848,0,256s114.848,256,256,256s256-114.848,256-256S397.152,0,256,0z M256,480 C132.48,480,32,379.52,32,256S132.48,32,256,32s224,100.48,224,224S379.52,480,256,480z" />
    <path d="M256,240c-26.016,0-48-14.656-48-32s21.984-32,48-32c14.72,0,28.352,4.608,37.376,12.608 c6.592,5.856,16.672,5.312,22.592-1.344c5.888-6.592,5.28-16.704-1.344-22.592c-11.2-9.952-26.24-16.672-42.624-19.328V128 c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v17.28c-36.48,5.952-64,31.808-64,62.72c0,35.296,35.904,64,80,64    c26.016,0,48,14.656,48,32s-21.984,32-48,32c-14.72,0-28.352-4.608-37.376-12.608c-6.592-5.888-16.704-5.312-22.592,1.344    c-5.888,6.592-5.28,16.704,1.344,22.592c11.2,9.984,26.24,16.672,42.624,19.36V384c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16    v-17.28c36.48-5.952,64-31.808,64-62.72C336,268.704,300.096,240,256,240z" />
  </svg>
)
