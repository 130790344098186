import type { SVGProps } from 'react'

export const PlusSign: React.FC<SVGProps<SVGElement>> = ({
  height = '1.25rem',
  width = '1.25rem',
  stroke = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    display="block"
  >
    <path
      d="M4 12H20M12 4V20"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
