import { useState, Dispatch, SetStateAction } from 'react'
import { useDebouncedValue } from '../../../common/utils/useDebouncedValue'
import { useApi } from '../../../api/ApiContext'
import { useQuery } from 'react-query'
import { queryKeys } from '../../../constants/query-keys'

type MentionUser = {
  id: string
  display: string
}

type UseMentionsReturn = {
  mentionUsers: MentionUser[]
  handlePostContentChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  mentionQuery: string
}

const useMentions = (
  postContent: string,
  setPostContent: Dispatch<SetStateAction<string>>
): UseMentionsReturn => {
  const [mentionQuery, setMentionQuery] = useState<string>('')
  const debouncedMentionQuery = useDebouncedValue(mentionQuery, 100)
  const { searchApi } = useApi()

  const { data: mentionUsers = [] } = useQuery(
    [queryKeys.promoPanel.default(debouncedMentionQuery)],
    async () => {
      if (!debouncedMentionQuery) return []
      const response = await searchApi.searchPost({
        query: debouncedMentionQuery,
      })
      return (
        response.channels?.map((channel) => ({
          id: channel.channelId,
          display: channel.displayName,
        })) || []
      )
    },
    {
      enabled: !!debouncedMentionQuery,
    }
  )

  const handlePostContentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value
    setPostContent(value)

    const lastAtSymbolIndex = value.lastIndexOf('@')
    const lastSpaceIndex = value.lastIndexOf(' ')

    const query =
      lastAtSymbolIndex > lastSpaceIndex
        ? value.slice(lastAtSymbolIndex + 1).trim()
        : ''
    setMentionQuery(query)
  }

  return { mentionUsers, handlePostContentChange, mentionQuery }
}

export default useMentions
