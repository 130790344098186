import type { SVGProps } from 'react'

export const EyeClosed: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0814 4.50977C18.3588 4.27545 18.3588 3.89555 18.0814 3.66124C17.8039 3.42693 17.354 3.42693 17.0765 3.66124L1.91863 16.4612C1.64116 16.6956 1.64116 17.0755 1.91863 17.3098C2.19611 17.5441 2.64599 17.5441 2.92347 17.3098L5.79875 14.8818C7.04721 15.3681 8.47649 15.6855 10 15.6855C12.48 15.6855 14.7103 14.8444 16.3174 13.8028C17.122 13.2814 17.7822 12.7022 18.2454 12.1386C18.6998 11.5857 19 11.0043 19 10.4855C19 9.96673 18.6998 9.38535 18.2454 8.83245C17.7822 8.26881 17.122 7.68965 16.3174 7.16819C16.0611 7.00204 15.7888 6.841 15.5023 6.68765L18.0814 4.50977ZM12.964 8.83107L11.9238 9.70946C12.057 9.94444 12.1316 10.2076 12.1316 10.4855C12.1316 11.4796 11.1772 12.2855 10 12.2855C9.67092 12.2855 9.35926 12.2225 9.08099 12.1101L8.0408 12.9885C8.60243 13.3026 9.27592 13.4855 10 13.4855C11.9621 13.4855 13.5526 12.1424 13.5526 10.4855C13.5526 9.87405 13.336 9.30533 12.964 8.83107Z"
      fill={fill}
    />
    <path
      d="M10.6443 7.53472C10.7183 7.54616 10.794 7.52648 10.8512 7.47819L12.6094 5.99351C12.7635 5.86338 12.7076 5.61516 12.5109 5.57047C11.7158 5.38982 10.8734 5.2855 10 5.2855C7.52001 5.2855 5.28969 6.12661 3.68258 7.16819C2.87798 7.68965 2.21778 8.26881 1.75457 8.83245C1.30018 9.38535 1 9.96673 1 10.4855C1 11.0043 1.30018 11.5857 1.75457 12.1386C2.15161 12.6217 2.69339 13.1162 3.34678 13.576C3.43836 13.6405 3.56176 13.6337 3.64732 13.5615L6.40896 11.2294C6.48236 11.1674 6.5127 11.0691 6.49438 10.9748C6.46345 10.8155 6.44737 10.6521 6.44737 10.4855C6.44737 8.82865 8.03794 7.4855 10 7.4855C10.22 7.4855 10.4354 7.50239 10.6443 7.53472Z"
      fill={fill}
    />
  </svg>
)
