import { PropsWithChildren, useMemo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useErrorNotification } from './translations/useErrorNotification'
import { ApiError } from './api/apiError'

const retryApiError = (failureCount: number, error: unknown): boolean => {
  if (!(error instanceof ApiError) || error.statusCode === undefined) {
    return false
  }

  if (error.statusCode >= 400 && error.statusCode < 500) {
    return false // No retry for custom or client errors (e.g. 400 Bad Request, 404 Not Found)
  }

  return error.statusCode >= 500 && failureCount < 1 // Retry once for (e.g. 500 Internal Server Error, 503 Service Unavailable)
}

export const QueryClientWithDefaultErrorHandlingProvider: React.FC<
  PropsWithChildren
> = ({ children }) => {
  const notifyError = useErrorNotification()

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: notifyError(() => {}),
            retry: retryApiError,
          },
          mutations: {
            onError: notifyError(() => {}),
            retry: retryApiError,
          },
        },
      }),
    [notifyError]
  )

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
