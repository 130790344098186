import type { SVGProps } from 'react'

export const Post: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    id="fi_2920015"
    enableBackground="new 0 0 512 512"
    width={width}
    fill={stroke}
    viewBox="0 0 512 512"
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m387.596 309.229v-205.21c0-9.645-7.847-17.492-17.492-17.492h-254.706c-9.645 0-17.492 7.847-17.492 17.492v205.209c0 9.645 7.847 17.492 17.492 17.492h254.706c9.645.001 17.492-7.846 17.492-17.491zm-274.697 0v-80.117l39.696-39.697c.636-.637 1.38-.732 1.767-.732s1.129.095 1.767.732l8.542 8.542c2.926 2.928 7.673 2.927 10.602 0 2.927-2.928 2.927-7.674-.001-10.602l-8.541-8.541c-3.303-3.304-7.696-5.124-12.368-5.124-4.673 0-9.065 1.819-12.369 5.123l-29.093 29.094v-103.888c0-1.378 1.121-2.499 2.499-2.499h254.706c1.378 0 2.499 1.121 2.499 2.499v205.209c0 1.378-1.121 2.499-2.499 2.499h-7.313l-93.401-93.402c-6.821-6.818-17.917-6.818-24.738.001l-19.203 19.204-26.289-26.289c-2.927-2.928-7.673-2.928-10.603 0-2.927 2.927-2.927 7.674 0 10.602l28.057 28.057c2.36 2.359 5.498 3.659 8.835 3.659s6.476-1.3 8.835-3.66l20.971-20.971c.974-.974 2.559-.974 3.534 0l82.798 82.799h-226.191c-1.378 0-2.499-1.121-2.499-2.498z"></path>
      <path d="m437.916 366.402v-96.007c0-4.14-3.356-7.497-7.497-7.497s-7.497 3.356-7.497 7.497v84.703c-12.56-7.438-27.197-11.726-42.823-11.726-42.918 0-78.424 32.241-83.631 73.771h-226.392c-4.134 0-7.497-3.363-7.497-7.497v-387.156c0-4.134 3.363-7.497 7.497-7.497h345.349c4.134 0 7.497 3.363 7.497 7.497v210.558c0 4.14 3.356 7.497 7.497 7.497s7.497-3.356 7.497-7.497v-210.558c0-12.401-10.089-22.49-22.49-22.49h-345.35c-12.401 0-22.49 10.089-22.49 22.49v387.156c0 12.401 10.089 22.49 22.49 22.49h225.839c.804 15.461 5.78 30.274 14.538 43.084 2.337 3.418 7.003 4.293 10.419 1.958 3.418-2.337 4.295-7.002 1.958-10.42-7.884-11.532-12.051-25.043-12.051-39.072 0-38.224 31.097-69.32 69.32-69.32s69.32 31.097 69.32 69.32-31.097 69.321-69.32 69.321c-11.395 0-22.69-2.822-32.664-8.161-3.651-1.953-8.194-.578-10.147 3.071-1.954 3.65-.579 8.193 3.072 10.147 12.142 6.5 25.884 9.935 39.739 9.935 46.491 0 84.314-37.823 84.314-84.314 0-24.125-10.201-45.9-26.497-61.283z"></path>
      <path d="m380.1 389.109c-4.141 0-7.497 3.356-7.497 7.497v23.799h-23.799c-4.141 0-7.497 3.356-7.497 7.497s3.356 7.497 7.497 7.497h23.799v23.799c0 4.14 3.356 7.497 7.497 7.497s7.497-3.356 7.497-7.497v-23.799h23.799c4.141 0 7.497-3.356 7.497-7.497s-3.356-7.497-7.497-7.497h-23.799v-23.799c-.001-4.14-3.357-7.497-7.497-7.497z"></path>
      <ellipse
        cx="115.904"
        cy="361.674"
        rx="10.501"
        ry="10.501"
        transform="matrix(.099 -.995 .995 .099 -255.43 441.376)"
      ></ellipse>
      <ellipse
        cx="153.013"
        cy="361.674"
        rx="10.501"
        ry="10.501"
        transform="matrix(.099 -.995 .995 .099 -221.978 478.303)"
      ></ellipse>
      <ellipse
        cx="190.121"
        cy="361.674"
        rx="10.501"
        ry="10.501"
        transform="matrix(.099 -.995 .995 .099 -188.527 515.231)"
      ></ellipse>
      <path d="m271.585 62.315c4.141 0 7.497-3.356 7.497-7.497s-3.356-7.497-7.497-7.497h-32.483c-4.141 0-7.497 3.356-7.497 7.497s3.356 7.497 7.497 7.497z"></path>
      <path d="m200.622 62.315c4.141 0 7.497-3.356 7.497-7.497s-3.356-7.497-7.497-7.497h-88.716c-4.141 0-7.497 3.356-7.497 7.497s3.356 7.497 7.497 7.497z"></path>
      <path d="m306.257 191.68c14.641 0 26.552-11.911 26.552-26.552s-11.911-26.552-26.552-26.552-26.552 11.911-26.552 26.552 11.911 26.552 26.552 26.552zm0-38.11c6.373 0 11.558 5.185 11.558 11.558s-5.185 11.558-11.558 11.558-11.558-5.185-11.558-11.558c-.001-6.372 5.185-11.558 11.558-11.558z"></path>
    </g>
  </svg>
)
