import type { SVGProps } from 'react'

export const SinglePost: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    id="fi_5977971"
    enableBackground="new 0 0 512 512"
    height={height}
    viewBox="0 0 512 512"
    width={width}
    fill={stroke}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m467 0h-422c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-13.309 0-408.659 0-422 0-24.813-20.187-45-45-45zm-437 45c0-8.271 6.729-15 15-15h422c8.271 0 15 6.729 15 15v20h-452zm452 422c0 8.271-6.729 15-15 15h-422c-8.271 0-15-6.729-15-15v-372h452z"></path>
      <path d="m104 383h304c8.284 0 15-6.716 15-15v-224c0-8.284-6.716-15-15-15h-304c-8.284 0-15 6.716-15 15v224c0 8.284 6.716 15 15 15zm15-30v-26.364l73.191-66.538 53.932 47.19c5.942 5.199 14.899 4.901 20.484-.683l76.751-76.751 49.642 55.849v67.297zm274-194v81.547l-37.789-42.513c-5.656-6.363-15.637-6.825-21.817-.641l-78.077 78.077-53.439-46.76c-5.737-5.021-14.327-4.938-19.967.189l-62.911 57.193v-127.092z"></path>
      <path d="m232 417h-32c-8.284 0-15 6.716-15 15s6.716 15 15 15h32c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
      <path d="m328 417h-32c-8.284 0-15 6.716-15 15s6.716 15 15 15h32c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
      <path d="m125.394 413.394-5.394 5.393-5.394-5.394c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l16 16c5.858 5.858 15.355 5.858 21.213 0l16-16c5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.212.001z"></path>
    </g>
  </svg>
)
