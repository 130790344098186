import React, { useEffect, useRef, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import { create, props } from '@stylexjs/stylex'
import { Dialog } from '../../components/dialog/Dialog'
import useLanguage from '../../translations/useLanguage'
import { Button } from '../../components/Button'
import { getCroppedImg } from './utils'

type ImageEditorProps = {
  isOpen: boolean
  imageUrl: string
  onSave: (croppedImageUrl: string) => void
  onClose: () => void
}

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxHeight: '60vh',
  },
  cropContainer: {
    width: '75vw',
    maxWidth: '900px',
    height: '75vh',
    maxHeight: '900px',
    position: 'relative',
    margin: '0 auto',
  },
  footerActions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
})

export const ImageEditor: React.FC<ImageEditorProps> = ({
  isOpen,
  imageUrl,
  onSave,
  onClose,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
  const dialogRef = useRef<HTMLDialogElement>(null)
  const { t } = useLanguage()

  useEffect(() => {
    if (!dialogRef.current) return
    if (isOpen) {
      dialogRef.current.showModal()
    } else {
      dialogRef.current.close()
    }
  }, [isOpen])

  const onCropComplete = (_: Area, croppedPixels: Area) => {
    setCroppedAreaPixels(croppedPixels)
  }

  const handleSave = async () => {
    if (!croppedAreaPixels) return
    const croppedImageUrl = await getCroppedImg(
      imageUrl,
      croppedAreaPixels,
      zoom
    )
    onSave(croppedImageUrl)
    onClose()
  }

  return (
    <Dialog
      ref={dialogRef}
      title={t('post.create.editImage')}
      variant="dual"
      closeOnOutsideClick={false}
      showCloseButton
      cancel={{
        label: t('shared.buttons.close'),
        action: onClose,
      }}
    >
      <div {...props(styles.container)}>
        <div {...props(styles.cropContainer)}>
          <Cropper
            image={imageUrl}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div {...props(styles.footerActions)}>
          <Button onClick={onClose}>{t('shared.buttons.cancel')}</Button>
          <Button variant="cta" onClick={handleSave}>
            {t('shared.buttons.save')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
