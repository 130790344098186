import React from 'react'
import {
  GetChannelInfoResponse,
  GetPostResponse,
  GetPostResponsePagedApiResponse,
} from '../../../client'
import { Button } from '../../../components/Button'
import { SmartPostCard } from '../../../post/SmartPostCard'
import { create, props } from '@stylexjs/stylex'
import { toast } from 'react-toastify'
import { useApi } from '../../../api/ApiContext'
import useLanguage from '../../../translations/useLanguage'
import { useInfiniteQuery } from 'react-query'
import { queryKeys } from '../../../constants/query-keys'
import { PostCardPlaceholder } from '../../../post/PostCardPlaceholder'
import { spacing } from '../../../styles/token.stylex'
import { POSTS_PER_PAGE } from '../../../constants'
import { EmptyPosts } from '../../../post/EmptyPosts'

type SmartChannelPostsProps = {
  channel: GetChannelInfoResponse
}

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraLarge,
  },
})

export const SmartChannelPosts: React.FC<SmartChannelPostsProps> = ({
  channel,
}) => {
  const { postApi } = useApi()
  const { t } = useLanguage()

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery<GetPostResponsePagedApiResponse, Error>({
      queryKey: queryKeys.posts.channel(channel.id),
      queryFn: async ({ pageParam = 1 }) =>
        postApi.postChannelChannelIdGet({
          channelId: channel.id,
          pageNumber: pageParam,
          pageSize: POSTS_PER_PAGE,
        }),
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < lastPage.totalPages
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: () => toast.error(t('feeds.errorToast')),
    })

  if (!data) {
    return <PostCardPlaceholder count={POSTS_PER_PAGE} />
  }

  const posts: GetPostResponse[] = data.pages.flatMap((page) => page.data)

  return (
    <div {...props(styles.container)}>
      {posts.length === 0 && <EmptyPosts isOwner={channel.isChannelOwner} />}
      {posts.map((post) => (
        <SmartPostCard
          key={post.postId}
          post={post}
          isPostCardsRestricted={true}
        />
      ))}
      {hasNextPage && (
        <Button
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          isLoading={isFetchingNextPage}
        >
          {isFetchingNextPage
            ? t('shared.buttons.loading')
            : t('shared.buttons.showMore')}
        </Button>
      )}
    </div>
  )
}
