import { useParams } from 'react-router-dom'
import { ChannelContextProvider } from './context/ChannelContextProvider'
import { SmartSinglePost } from '../post/SmartSinglePost'

export const SingleChannelPage: React.FC = () => {
  const { postId, slug } = useParams()

  if (!slug) throw new Error('Slug not found')
  if (!postId) throw new Error('Post ID not found')

  return (
    <ChannelContextProvider slug={slug}>
      <SmartSinglePost postId={postId} />
    </ChannelContextProvider>
  )
}
