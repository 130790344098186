import { create } from '@stylexjs/stylex'
import { color, fontSize } from '../../styles/token.stylex'

export const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '320px',
    position: 'relative',
  },
  actionsContainer: {
    display: 'flex',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  vatDisplay: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: fontSize.extraSmall,
  },
  vatText: {
    color: color.dustyGray,
  },
  totalText: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontSize: fontSize.xxSmall,
  },
  totalTextLabel: {
    color: color.dustyGray,
  },
  totalTextAmount: {
    color: color.white,
  },
  hidden: {
    display: 'none',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'calc(100% + 5px)',
    height: 'calc(100% + 5px)',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    backgroundColor: color.codGrayLight,
  },
})
