import { create, props } from '@stylexjs/stylex'
import React from 'react'
import useLanguage from '../../../translations/useLanguage'
import { fonts, titleFonts } from '../../../styles/font-styles'
import { spacing } from '../../../styles/token.stylex'
import { TextLink } from '../../../components/TextLink'

type EmptyPromoPanelProps = {
  isOwner: boolean
}

const styles = create({
  container: {
    paddingTop: spacing.normal,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },
  title: {},
  description: {},
})

const EmptyPromoPanel: React.FC<EmptyPromoPanelProps> = ({ isOwner }) => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.title, titleFonts.largeSemiBold)}>
        {isOwner
          ? t('myChannel.promoPanel.empty.owner.title')
          : t('myChannel.promoPanel.empty.user.title')}
      </div>
      <div {...props(styles.description, fonts.smallRegular)}>
        {isOwner
          ? t('myChannel.promoPanel.empty.owner.description')
          : t('myChannel.promoPanel.empty.user.description')}
        <TextLink to="https://app.clickup.com/t/86c0ux3g9" isExternal>
          {' '}
          {isOwner
            ? t('myChannel.promoPanel.empty.owner.linkTitle')
            : t('myChannel.promoPanel.empty.user.linkTitle')}
        </TextLink>
      </div>
    </div>
  )
}

export default EmptyPromoPanel
