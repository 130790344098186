import { toast } from 'react-toastify'
import { LanguageKey } from '../translations/useLanguage'

export const handleProfileCopy = (
  text: string,
  t: (key: LanguageKey) => string
) => {
  navigator.clipboard.writeText(text).then(
    () => toast.success(t('channel.actions.copyLinkToProfile.success')),
    () => toast.error(t('channel.actions.copyLinkToProfile.error'))
  )
}
