import React, { useRef } from 'react'
import { GetChannelInfoResponse } from '../client'
import { create, props } from '@stylexjs/stylex'
import DefaultBannerUrl from '../assets/banner/banner.webp?url'
import DefaultProfilePicture from '../../public/default-profile-image.png'
import { Avatar } from '../components/avatars/Avatar'
import { color, spacing } from '../styles/token.stylex'
import { FullCreatorInfo } from '../components/FullCreatorInfo'
import { ContextMenuDots } from '../components/icons/ContextMenuDots'
import { ContextMenu } from '../components/ContextMenu'
import { ContextMenuItem } from '../components/types'
import useLanguage from '../translations/useLanguage'
import { handleProfileCopy } from './utils'
import { IconButton } from '../components/IconButton'
import { ExpandableText } from '../components/ExpandableText'
import { replaceLinksWithAnchors } from '../common/utils/LinkUtils'
import { Stats } from '../stats/Stats'
import { useUserContext } from '../auth/user-context'
import { Dialog } from '../components/dialog/Dialog'
import { ReportingModalContent } from '../reporting/ReportModalContent'
import { Copy } from '../components/icons/Copy'
import { responsiveStyles } from '../layout/navbar/styles'
import { Currency } from '../components/icons/Currency'
import { routes } from '../router/routes'
import { Button } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { TipModalContent } from '../post/tip/TipModalContent'

type ProfileInfoProps = {
  channel: GetChannelInfoResponse
  expandableDescription?: boolean
}

const largeScreen = '@media (min-width: 768px)'

const mobileAvatarSize = 4.5
const desktopAvatarSize = 6

const styles = create({
  container: {},
  profileHeader: {},
  banner: {
    position: 'relative',
  },
  bannerHeader: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: {
      default: `${spacing.small} ${spacing.normal}`,
      [largeScreen]: `${spacing.small} ${spacing.large}`,
    },
  },
  stats: {},
  bannerImage: {
    width: '100%',
    height: {
      default: '11rem',
      [largeScreen]: '14rem',
    },
    objectFit: 'cover',
  },
  contextMenu: {
    backgroundColor: 'rgba(47, 47, 47, 0.50)',
    backdropFilter: 'blur(2px)',
    borderRadius: spacing.xxSmall,
    padding: spacing.xxSmall,
    display: 'flex',
    justifyContent: 'center',
  },
  profileImageContainer: {
    marginTop: {
      default: '-3rem',
      [largeScreen]: '-3rem',
    },
    marginRight: spacing.normal,
  },
  userContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: spacing.small,
  },
  profileActions: {
    maxWidth: {
      default: `calc(100% - ${mobileAvatarSize - 1}rem)`,
      [largeScreen]: `calc(100% - ${desktopAvatarSize - 1}rem)`,
    },
    display: 'flex',
    marginLeft: 'auto',
    gap: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
    paddingTop: {
      default: 0,
      [largeScreen]: spacing.extraSmall,
    },
    paddingRight: {
      default: 0,
      [largeScreen]: spacing.extraSmall,
    },
    justifyContent: 'flex-end',
  },
  profileInfoContainer: {
    marginTop: {
      default: `-${(mobileAvatarSize - 1) / 2}rem`,
      [largeScreen]: `-${(desktopAvatarSize - 1) / 2}rem`,
    },
    paddingTop: {
      default: `${mobileAvatarSize / 2}rem`,
      [largeScreen]: `${desktopAvatarSize / 2}rem`,
    },
    paddingInline: spacing.normal,
  },
  name: {
    marginTop: {
      default: spacing.xxxSmall,
      [largeScreen]: spacing.normal,
    },
    width: '100%',
    maxWidth: '100%',
  },
})

const CopyProfileMenuIcon = () => <Copy width="1.25rem" height="1.25rem" />
const ContextMenuIcon = () => <ContextMenuDots />
const CurrencyIcon = () => <Currency fill={color.gold} />

export const ProfileInfo: React.FC<ProfileInfoProps> = ({
  channel,
  expandableDescription,
}) => {
  const { t } = useLanguage()
  const { data } = useUserContext()
  const reportDialogRef = useRef<HTMLDialogElement>(null)
  const tipDialogRef = useRef<HTMLDialogElement>(null)
  const navigate = useNavigate()

  const ownProfile = data && data.streamerChannel.id === channel.id
  const bannerImageUrl = channel.bannerPicture?.source ?? DefaultBannerUrl
  const profileImage =
    channel.user.profilePicture?.source ?? DefaultProfilePicture

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: t('channel.actions.copyLinkToProfile.title'),
      action: () => handleProfileCopy(window.location.href, t),
    },
    ...(!!data && !ownProfile
      ? [
          {
            label: t('channel.actions.report.title'),
            action: () => reportDialogRef.current?.showModal(),
          },
        ]
      : []),
  ]

  const formattedDescription =
    channel.description &&
    replaceLinksWithAnchors(channel.description, undefined, true)

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.profileHeader)}>
        <div {...props(styles.banner)}>
          <img
            {...props(styles.bannerImage)}
            src={bannerImageUrl}
            alt={channel.user.displayName}
          />
          <div {...props(styles.bannerHeader)}>
            <div {...props(styles.stats)}>
              <Stats stats={{ ...channel.stats, postsCount: undefined }} />
            </div>
            <div {...props(styles.contextMenu)}>
              {!!contextMenuItems?.length && (
                <ContextMenu items={contextMenuItems}>
                  <IconButton
                    icon={ContextMenuIcon}
                    variant="large"
                    color="white"
                  />
                </ContextMenu>
              )}
            </div>
          </div>
        </div>
      </div>
      <div {...props(styles.profileInfoContainer)}>
        <div {...props(styles.userContent)}>
          <div
            {...props(
              styles.profileImageContainer,
              responsiveStyles.desktopFlex
            )}
          >
            <Avatar
              src={profileImage}
              url={channel.slug}
              alt={channel.user.displayName}
              imageVariant="xlarge"
              showBorder
              isLive={channel.isLive}
            />
          </div>
          <div
            {...props(styles.profileImageContainer, responsiveStyles.phoneFlex)}
          >
            <Avatar
              src={profileImage}
              url={channel.slug}
              alt={channel.user.displayName}
              imageVariant="large"
              showBorder
              isLive={channel.isLive}
            />
          </div>
          <div {...props(styles.profileActions)}>
            {ownProfile && (
              <Button
                variant="cta"
                onClick={() => navigate(routes.profileRoutes.myProfile)}
              >
                {t('channel.actions.editProfile.title')}
              </Button>
            )}
            {!!data && !ownProfile && (
              <IconButton
                icon={CurrencyIcon}
                background="secondary"
                tooltip={t('tip.sendTip.title')}
                onClick={() => tipDialogRef.current?.showModal()}
                variant="extraLarge"
              />
            )}
            <IconButton
              background="secondary"
              icon={CopyProfileMenuIcon}
              tooltip={t('channel.actions.copyLinkToProfile.title')}
              color="white"
              onClick={() => handleProfileCopy(window.location.href, t)}
              variant="extraLarge"
            />
          </div>
          <div {...props(styles.name)}>
            <FullCreatorInfo
              fullName={channel.user.displayName}
              displayName={channel.user.displayName}
              hideImage
              isVerified={channel.isVerified}
            />
          </div>
        </div>
        <div {...props(responsiveStyles.desktopFlex)}>
          {formattedDescription && (
            <div>
              {expandableDescription ? (
                <ExpandableText text={formattedDescription} maxLines={6} />
              ) : (
                formattedDescription
              )}
            </div>
          )}
        </div>
        <div {...props(responsiveStyles.phoneFlex)}>
          {formattedDescription && (
            <div>
              {expandableDescription ? (
                <ExpandableText text={formattedDescription} maxLines={4} />
              ) : (
                formattedDescription
              )}
            </div>
          )}
        </div>
      </div>
      <Dialog
        ref={reportDialogRef}
        title={t('channel.actions.report.reportProfile')}
        showCloseButton={true}
        cancel={{
          label: t('shared.buttons.cancel'),
          action: () => reportDialogRef.current?.close(),
        }}
      >
        <ReportingModalContent
          onClose={() => reportDialogRef.current?.close()}
          resourceId={channel.id}
          reportType="channel"
        />
      </Dialog>
      {!!data && !ownProfile && (
        <Dialog
          closeOnOutsideClick={false}
          ref={tipDialogRef}
          title={t('tip.sendTip.title')}
          showCloseButton={true}
          cancel={{
            label: t('shared.buttons.cancel'),
            action: () => tipDialogRef.current?.close(),
          }}
        >
          <TipModalContent
            maximumPrice={channel.tipConfiguration.maxAmount}
            minimumPrice={channel.tipConfiguration.minAmount}
            channelId={channel.id}
            recipient={{
              displayName: channel.user.displayName,
              fullName: channel.user.displayName,
              isVerified: channel.isVerified,
              profilePictureUrl: channel.user.profilePicture?.source,
              userId: channel.user.id,
            }}
            tipSource="profile"
          />
        </Dialog>
      )}
    </div>
  )
}
