import { useState, useRef } from 'react'
import { ValidatedFile } from '../../common/utils/fileValidationUtils'
import useLanguage from '../../translations/useLanguage'
import { useDraggable } from 'react-use-draggable-scroll'
import { useUploadHandler } from './hooks/useUploadHandler'
import { useDropzone } from 'react-dropzone'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { props } from '@stylexjs/stylex'
import { Panel } from '../../components/Panel'
import { styles } from './CreatePostStyles'
import { Media } from '../../components/icons/Media'
import { PlusWithoutCircle } from '../../components/icons/PlusWithoutCircle'
import { FilePreview } from './FilePreview'
import { ImageEditor } from './ImageEditor'
import { filterValidAssetIds, isPostValid, processCroppedImage } from './utils'
import { useCreatePost } from './hooks/useCreatePost'
import { MentionsTextArea } from '../../components/input/MentionTextArea'
import useMentions from './hooks/useMentions'
import { Tooltip } from '../../components/Tooltip'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../router/routes'
import { Dialog } from '../../components/dialog/Dialog'
import { IconButton } from '../../components/IconButton'
import { PaidPostModalContent } from './paid/PaidPostModalContent'
import { Currency } from '../../components/icons/Currency'
import { Button } from '../../components/Button'
import { GetChannelInfoResponse } from '../../client'

const CurrencyIcon = () => <Currency width={'1.25rem'} height={'1.25rem'} />
const MediaIcon = () => <Media width={'1.25rem'} height={'1.25rem'} />

type CreatePostProps = {
  channel: GetChannelInfoResponse
}

export const CreatePost: React.FC<CreatePostProps> = ({ channel }) => {
  const [postContent, setPostContent] = useState('')
  const [editingImage, setEditingImage] = useState<ValidatedFile>()
  const { t, formatCurrency } = useLanguage()
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
  const paidPostDialogRef = useRef<HTMLDialogElement>(null)
  const { events } = useDraggable(ref)
  const createPost = useCreatePost()
  const navigate = useNavigate()
  const [postPrice, setPostPrice] = useState<number>(0)

  const {
    fileData,
    addFiles,
    removeFile,
    clearFiles,
    uploadInProgress,
    updateFileDataById,
    uploadFile,
  } = useUploadHandler()

  const { mentionUsers, handlePostContentChange } = useMentions(
    postContent,
    setPostContent
  )

  const isFreeToSubscribe =
    channel.subscriptionOptions.subscribePrice.amount === 0

  const handlePost = () => {
    if (!isPostValid(postContent, fileData, postPrice, isFreeToSubscribe))
      return

    createPost.mutate(
      {
        text: postContent,
        assetIds: filterValidAssetIds(fileData),
        price: postPrice || null,
      },
      {
        onSuccess: (createdPost) => {
          navigate(
            routes.channel.postById(createdPost.postId, createdPost.author.slug)
          )
        },
      }
    )
    setPostContent('')
    clearFiles()
  }

  const handleSaveCrop = async (croppedImageUrl: string) => {
    if (!editingImage) return

    await processCroppedImage(
      croppedImageUrl,
      editingImage,
      updateFileDataById,
      uploadFile
    )

    setEditingImage(undefined)
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: addFiles,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.heif'],
      'video/*': ['.mp4', '.mov', '.avi', '.mkv', '.webm'],
    },
  })

  if (createPost.isLoading) return <LoadingIndicator />

  return (
    <div {...props(styles.container)}>
      <Panel
        title={t('feeds.NewPost')}
        variant="createPost"
        actionButtons={{
          secondary: {
            label: t('feeds.Clear'),
            disabled: postContent === '' && fileData.length === 0,
            onClick: () => {
              setPostContent('')
              clearFiles()
            },
          },
          primary: {
            label: t('feeds.Post'),
            disabled:
              !isPostValid(
                postContent,
                fileData,
                postPrice,
                isFreeToSubscribe
              ) || uploadInProgress,
            onClick: handlePost,
          },
        }}
      >
        <div
          {...props(styles.dropzoneArea, isDragActive && styles.dragActive)}
          {...getRootProps()}
        >
          <div
            {...props(
              styles.previewWrapper,
              fileData.length === 0 && styles.hidden
            )}
            ref={ref}
            {...events}
          >
            <FilePreview
              fileData={fileData}
              removeFile={removeFile}
              onEditFile={setEditingImage}
            />
            <div onClick={open} {...props(styles.addButton)}>
              <Tooltip text={t('post.create.addMedia')}>
                <PlusWithoutCircle />
              </Tooltip>
            </div>
          </div>
          <input {...props(styles.hidden)} {...getInputProps()} />
          <div {...props(styles.textAreaWrapper)}>
            <MentionsTextArea
              id="create-post-input"
              value={postContent}
              onChange={handlePostContentChange}
              mentionData={mentionUsers}
              placeholder={
                isDragActive
                  ? t('feeds.TextareaPlaceholderActiveDrag')
                  : t('feeds.TextareaPlaceholderInitial')
              }
              maxLength={500}
              required
            />
          </div>
          <div
            {...props(styles.actionButtonsWrapper, !!postPrice && styles.paid)}
          >
            <div>
              <IconButton
                onClick={open}
                tooltip={t('post.create.addMedia')}
                icon={MediaIcon}
                color="white"
              />
            </div>

            <div {...props(!isFreeToSubscribe && styles.hidden)}>
              <IconButton
                icon={CurrencyIcon}
                tooltip={t('post.paidPost.postPrice')}
                onClick={() => paidPostDialogRef.current?.showModal()}
                color={!postPrice ? 'white' : 'gold'}
              />
            </div>
            <div {...props(styles.priceTag, !postPrice && styles.hidden)}>
              <Button onClick={() => paidPostDialogRef.current?.showModal()}>
                {formatCurrency(postPrice, {
                  currency: 'JPY',
                })}
              </Button>
            </div>
          </div>
        </div>
      </Panel>

      <Dialog
        ref={paidPostDialogRef}
        title={t('post.paidPost.postPrice')}
        showCloseButton={true}
        cancel={{
          label: t('shared.buttons.cancel'),
          action: () => {
            paidPostDialogRef.current?.close()
          },
        }}
        closeOnOutsideClick={false}
      >
        <PaidPostModalContent
          maximumPrice={channel.getPurchaseConfigurationResponse.maxAmount}
          minimumPrice={channel.getPurchaseConfigurationResponse.minAmount}
          price={postPrice}
          onClose={() => paidPostDialogRef.current?.close()}
          onPriceSubmit={setPostPrice}
        />
      </Dialog>

      {editingImage && (
        <ImageEditor
          isOpen={!!editingImage}
          imageUrl={editingImage.url}
          onSave={handleSaveCrop}
          onClose={() => setEditingImage(undefined)}
        />
      )}
    </div>
  )
}
