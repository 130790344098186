import { create, props } from '@stylexjs/stylex'
import useLanguage from '../../translations/useLanguage'
import { TextLink } from '../../components/TextLink'
import { spacing } from '../../styles/token.stylex'
import { fonts, titleFonts } from '../../styles/font-styles'

type NotPostFoundPageProps = {
  slug: string
}

const styles = create({
  container: {
    maxWidth: '888px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.normal,
  },
  linkContainer: {
    marginTop: spacing.normal,
  },
})

export const NotPostFoundPage: React.FC<NotPostFoundPageProps> = ({ slug }) => {
  const { t } = useLanguage()
  return (
    <div {...props(styles.container)}>
      <h1 {...props(titleFonts.xxxlSemiBold)}>{t('post.notFound.title')}</h1>
      <p {...props(fonts.normalRegular)}>{t('post.notFound.message')}</p>
      <div {...props(styles.linkContainer)}>
        <TextLink to={`/${slug}`}>{t('post.notFound.label')}</TextLink>
      </div>
    </div>
  )
}
