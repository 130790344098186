import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import { UnlockableItem } from './UnlockableItem'
import { StatInfo } from '../../stats/types'
import { Stats } from '../../stats/Stats'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    color: color.unlockedCardTextColor,
    backgroundColor: color.unlockedCardBackgroundColor,
    borderRadius: '.5rem',
    height: {
      default: '200px',
      [largeScreen]: '275px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: spacing.normal,
  },
  text: {
    display: 'flex',
    gap: spacing.xxSmall,
  },
  price: {
    color: color.unlockedCardPriceColor,
  },
})

type UnlockCardProps = {
  stats: StatInfo
}

export const UnlockCard: React.FC<UnlockCardProps> = ({ stats }) => {
  return (
    <div {...props(styles.container)}>
      <div {...props(styles.content)}>
        <UnlockableItem iconSize="xLarge">
          <div {...props(styles.text, fonts.normalSemiBold)}>
            <Stats stats={stats} />
          </div>
        </UnlockableItem>
      </div>
    </div>
  )
}
