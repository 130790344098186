import { create, props } from '@stylexjs/stylex'
import useLanguage from '../../translations/useLanguage'
import { routes } from '../../router/routes'
import { HELPDESK_URL } from '../../constants/sites'
import { Logo } from '../../components/logos/Logo'
import { LanguageSelector } from './LanguageSelector'
import { spacing } from '../../styles/token.stylex'
import { TextLink } from '../../components/TextLink'
import { useUnderConstructionDialog } from '../../style-guide/components/under-construction/under-construction-context'

const styles = create({
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${spacing.large} ${spacing.normal}`,
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: spacing.normal,
  },
  logoContainer: {
    padding: spacing.normal,
  },
  languageContainer: {
    paddingTop: spacing.normal,
  },
})

export const Footer: React.FC = () => {
  const { t, languageCode } = useLanguage()
  const { openDialog } = useUnderConstructionDialog()

  return (
    <footer>
      <div {...props(styles.footerContainer)}>
        <div {...props(styles.logoContainer)}>
          <Logo />
        </div>
        <div {...props(styles.linkContainer)}>
          <TextLink to={routes.communityGuidelines} variant="footer">
            {t('userMenu.communityGuidelines')}
          </TextLink>
          <TextLink to={routes.dmcaPolicy} variant="footer">
            {t('userMenu.dmcaPolicy')}
          </TextLink>
          <TextLink to={routes.privacyPolicy} variant="footer">
            {t('userMenu.privacyPolicy')}
          </TextLink>
          <TextLink to={routes.termsOfService} variant="footer">
            {t('userMenu.termsOfService')}
          </TextLink>
          <TextLink to={`${HELPDESK_URL}/${languageCode}`} variant="footer">
            {t('userMenu.helpAndSupport')}
          </TextLink>
          <TextLink onClick={() => openDialog('86c0wzuqg')} variant="footer">
            {`CU-86c0wzuqg`}
          </TextLink>
        </div>
        <div {...props(styles.languageContainer)}>
          <LanguageSelector />
        </div>
      </div>
    </footer>
  )
}
