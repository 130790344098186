import { create, props } from '@stylexjs/stylex'
import React from 'react'
import { spacing } from '../styles/token.stylex'
import { fonts } from '../styles/font-styles'
import { Heart } from '../components/icons/Heart'
import { formatCount } from '../components/utils'
import { Photo } from '../components/icons/Photo'
import { Video } from '../components/icons/Video'
import { Stream } from '../components/icons/Stream'
import { SinglePost } from '../components/icons/SinglePost'
import { Tooltip } from '../components/Tooltip'
import useLanguage from '../translations/useLanguage'
import { StatInfo } from './types'

type StatsProps = {
  stats: StatInfo
}

const styles = create({
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: spacing.xSmall,
    backgroundColor: 'rgba(47, 47, 47, 0.50)',
    backdropFilter: 'blur(2px)',
  },
  statsItemContainer: {
    display: 'flex',
    paddingInline: spacing.extraSmall,
    paddingBlock: spacing.xSmall,
    alignItems: 'center',
    gap: spacing.xSmall,
  },
})

export const Stats: React.FC<StatsProps> = ({
  stats: { photosCount, videosCount, streamsCount, likesCount, postsCount },
}) => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.statsContainer, fonts.smallRegular)}>
      {postsCount !== undefined && postsCount > 0 && (
        <Tooltip text={t('channel.stats.posts')}>
          <div {...props(styles.statsItemContainer)}>
            <SinglePost width="0.875rem" height="0.875rem" />{' '}
            <span>{formatCount(postsCount)}</span>
          </div>
        </Tooltip>
      )}
      {photosCount !== undefined && photosCount > 0 && (
        <Tooltip text={t('channel.stats.photos')}>
          <div {...props(styles.statsItemContainer)}>
            <Photo width="1rem" height="1rem" />{' '}
            <span>{formatCount(photosCount)}</span>
          </div>
        </Tooltip>
      )}
      {videosCount !== undefined && videosCount > 0 && (
        <Tooltip text={t('channel.stats.videos')}>
          <div {...props(styles.statsItemContainer)}>
            <Video width="1.25rem" height="1.25rem" />{' '}
            <span>{formatCount(videosCount)}</span>
          </div>
        </Tooltip>
      )}
      {streamsCount !== undefined && streamsCount > 0 && (
        <Tooltip text={t('channel.stats.streams')}>
          <div {...props(styles.statsItemContainer)}>
            <Stream width="1rem" height="1rem" />{' '}
            <span>{formatCount(streamsCount)}</span>
          </div>
        </Tooltip>
      )}
      {likesCount !== undefined && likesCount > 0 && (
        <Tooltip text={t('channel.stats.likes')}>
          <div {...props(styles.statsItemContainer)}>
            <Heart width="1rem" height="1rem" />{' '}
            <span>{formatCount(likesCount)}</span>
          </div>
        </Tooltip>
      )}
    </div>
  )
}
