import React, { forwardRef } from 'react'
import Select, { SingleValue, SelectInstance } from 'react-select'
import { InputPanel } from './InputPanel'
import { color } from '../../styles/token.stylex'
import { create, props } from '@stylexjs/stylex'
import { customSelectStyles } from './styles'

const styles = create({
  formatOptionLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
})

export type Option = {
  label: React.ReactNode
  value: string
  icon?: React.FC<React.SVGProps<SVGElement>>
}

type ReactSelectProps = {
  id: string
  label?: string
  placeholder?: string
  options: Option[]
  value: string | undefined
  required?: boolean
  onChange: (value: string) => void
}

export const ReactSelect = forwardRef<SelectInstance<Option>, ReactSelectProps>(
  ({ id, placeholder, label, options, value, required, onChange }, ref) => {
    const selectedOption = options.find((option) => option.value === value)

    const handleChange = (newValue: SingleValue<Option>) =>
      newValue && onChange(newValue.value)

    const formatOptionLabel = ({ label, icon: Icon }: Option) => (
      <div {...props(styles.formatOptionLabel)}>
        {Icon && <Icon width={20} height={20} stroke={color.gold} />}
        <span>{label}</span>
      </div>
    )

    return (
      <InputPanel id={id} label={label} required={required}>
        <Select
          ref={ref}
          id={id}
          options={options}
          value={selectedOption}
          onChange={handleChange}
          placeholder={placeholder}
          styles={customSelectStyles}
          formatOptionLabel={formatOptionLabel}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </InputPanel>
    )
  }
)

ReactSelect.displayName = 'ReactSelect'
