/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetChannelStatsResponse
 */
export interface GetChannelStatsResponse {
    /**
     * Number of photos in the uploaded to posts.
     * @type {number}
     * @memberof GetChannelStatsResponse
     */
    photosCount: number;
    /**
     * Number of videos uploaded to posts.
     * @type {number}
     * @memberof GetChannelStatsResponse
     */
    videosCount: number;
    /**
     * Number of finished stream sessions (created video).
     * @type {number}
     * @memberof GetChannelStatsResponse
     */
    streamsCount: number;
    /**
     * Number of likes on all posts of the channel.
     * @type {number}
     * @memberof GetChannelStatsResponse
     */
    likesCount: number;
    /**
     * Number of posts created by the channel.
     * @type {number}
     * @memberof GetChannelStatsResponse
     */
    postsCount: number;
}

/**
 * Check if a given object implements the GetChannelStatsResponse interface.
 */
export function instanceOfGetChannelStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "photosCount" in value;
    isInstance = isInstance && "videosCount" in value;
    isInstance = isInstance && "streamsCount" in value;
    isInstance = isInstance && "likesCount" in value;
    isInstance = isInstance && "postsCount" in value;

    return isInstance;
}

export function GetChannelStatsResponseFromJSON(json: any): GetChannelStatsResponse {
    return GetChannelStatsResponseFromJSONTyped(json, false);
}

export function GetChannelStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photosCount': json['photosCount'],
        'videosCount': json['videosCount'],
        'streamsCount': json['streamsCount'],
        'likesCount': json['likesCount'],
        'postsCount': json['postsCount'],
    };
}

export function GetChannelStatsResponseToJSON(value?: GetChannelStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photosCount': value.photosCount,
        'videosCount': value.videosCount,
        'streamsCount': value.streamsCount,
        'likesCount': value.likesCount,
        'postsCount': value.postsCount,
    };
}

