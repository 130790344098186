import React from 'react'
import { useForm, Controller, FieldValues } from 'react-hook-form'
import { Input } from '../../../components/input/Input'
import { Button } from '../../../components/Button'
import { props } from '@stylexjs/stylex'
import { handleKeyDown, sanitizeInput } from '../../tip/utils'
import { Warning } from '../../../components/icons/Warning'
import { color } from '../../../styles/token.stylex'
import { styles } from './PaidPostModalStyles'
import { paidPostValidator } from './utils'
import useLanguage from '../../../translations/useLanguage'

type PaidPostModalContentProps = {
  price: number
  minimumPrice: number
  maximumPrice: number
  onPriceSubmit: (price: number) => void
  onClose: () => void
}

export const PaidPostModalContent: React.FC<PaidPostModalContentProps> = ({
  minimumPrice,
  maximumPrice,
  price,
  onPriceSubmit,
  onClose,
}) => {
  const { t, formatCurrency } = useLanguage()

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      price: price || '',
    },
    resolver: async (values) => {
      const validation = paidPostValidator(
        { price: values.price.toString() },
        t,
        formatCurrency,
        minimumPrice,
        maximumPrice
      )
      return validation
    },
  })

  const onSubmit = (formData: FieldValues) => {
    const priceValue = Number(formData.price)
    onPriceSubmit(priceValue)
  }

  return (
    <div {...props(styles.container)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              required
              type="number"
              prefix="¥"
              placeholder={t('post.paidPost.free')}
              label={t('post.paidPost.price')}
              instruction={t('post.paidPost.minimumPrice', {
                minimumPrice: formatCurrency(minimumPrice, {
                  currency: 'JPY',
                }),
              })}
              error={errors.price?.message}
              postfix={
                !isValid && (
                  <div {...props(styles.warning)}>
                    <Warning
                      fill={color.error}
                      width={'1.25rem'}
                      height={'1.25rem'}
                    />
                  </div>
                )
              }
              onChange={(e) =>
                field.onChange(sanitizeInput(e.target.value, 10))
              }
              onKeyDown={handleKeyDown}
            />
          )}
        />

        <div {...props(styles.actionsContainer)}>
          <Button type="reset" disabled={!isValid} onClick={onClose}>
            {t('post.paidPost.cancel')}
          </Button>
          <Button
            type="submit"
            variant="cta"
            disabled={!isValid || price === Number(watch('price'))}
            onClick={onClose}
          >
            {t('post.paidPost.save')}
          </Button>
        </div>
      </form>
    </div>
  )
}
