import React from 'react'
import { useApi } from '../api/ApiContext'
import { useInfiniteQuery } from 'react-query'
import { fontSize, spacing } from '../styles/token.stylex'
import { Button } from '../components/Button'
import { toast } from 'react-toastify'
import { create, props } from '@stylexjs/stylex'
import useLanguage from '../translations/useLanguage'
import { SmartPostCard } from './SmartPostCard'
import { GetPostResponse, GetPostResponsePagedApiResponse } from '../client'
import { queryKeys } from '../constants/query-keys'
import { PostCardPlaceholder } from './PostCardPlaceholder'
import { useUserContext } from '../auth/user-context'
import { POSTS_PER_PAGE } from '../constants'
import { CreatePostPage } from './CreatePostPage'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.extraLarge,
    maxWidth: '888px',
    margin: 'auto',
  },
  title: {
    fontSize: {
      default: fontSize.large,
      [largeScreen]: fontSize.xxl,
    },
  },
})

const FeedsPage: React.FC = () => {
  const { postApi } = useApi()
  const { t } = useLanguage()
  const { isContentCreator } = useUserContext()

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery<GetPostResponsePagedApiResponse, Error>({
      queryKey: queryKeys.posts.default,
      queryFn: async ({ pageParam = 1 }) =>
        postApi.postGet({
          pageNumber: pageParam,
          pageSize: POSTS_PER_PAGE,
        }),
      getNextPageParam: (lastPage) =>
        (lastPage.pageNumber ?? 1) < lastPage.totalPages
          ? (lastPage.pageNumber ?? 1) + 1
          : undefined,
      onError: () => toast.error(t('feeds.errorToast')),
    })

  if (!data) {
    return (
      <PostCardPlaceholder
        count={POSTS_PER_PAGE}
        title={t('feeds.headerTitle')}
      />
    )
  }

  const posts: GetPostResponse[] = data.pages.flatMap((page) => page.data)

  return (
    <div {...props(styles.container)}>
      {isContentCreator && <CreatePostPage />}
      {!isContentCreator && (
        <div>
          <h1 {...props(styles.title)}>{t('feeds.headerTitle')}</h1>
        </div>
      )}

      {posts.length === 0 && (
        <div>
          <p>{t('feeds.noPostsMessage')}</p>
        </div>
      )}
      {posts.map((post) => (
        <SmartPostCard
          key={post.postId}
          post={post}
          isPostCardsRestricted={false}
        />
      ))}
      {hasNextPage && (
        <Button
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          isLoading={isFetchingNextPage}
        >
          {isFetchingNextPage
            ? t('shared.buttons.loading')
            : t('shared.buttons.showMore')}
        </Button>
      )}
    </div>
  )
}

export default FeedsPage
