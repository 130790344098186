/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetMoneyResponse } from './GetMoneyResponse';
import {
    GetMoneyResponseFromJSON,
    GetMoneyResponseFromJSONTyped,
    GetMoneyResponseToJSON,
} from './GetMoneyResponse';

/**
 * 
 * @export
 * @interface GetPostPurchaseResponse
 */
export interface GetPostPurchaseResponse {
    /**
     * 
     * @type {GetMoneyResponse}
     * @memberof GetPostPurchaseResponse
     */
    price: GetMoneyResponse;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostPurchaseResponse
     */
    isPaid: boolean;
    /**
     * 
     * @type {GetMoneyResponse}
     * @memberof GetPostPurchaseResponse
     */
    purchase?: GetMoneyResponse;
}

/**
 * Check if a given object implements the GetPostPurchaseResponse interface.
 */
export function instanceOfGetPostPurchaseResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isPaid" in value;

    return isInstance;
}

export function GetPostPurchaseResponseFromJSON(json: any): GetPostPurchaseResponse {
    return GetPostPurchaseResponseFromJSONTyped(json, false);
}

export function GetPostPurchaseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPostPurchaseResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': GetMoneyResponseFromJSON(json['price']),
        'isPaid': json['isPaid'],
        'purchase': !exists(json, 'purchase') ? undefined : GetMoneyResponseFromJSON(json['purchase']),
    };
}

export function GetPostPurchaseResponseToJSON(value?: GetPostPurchaseResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': GetMoneyResponseToJSON(value.price),
        'isPaid': value.isPaid,
        'purchase': GetMoneyResponseToJSON(value.purchase),
    };
}

