import { Navigate } from 'react-router-dom'
import { useApi } from '../api/ApiContext'
import { useQuery } from 'react-query'
import { useState } from 'react'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { SmartPostCard } from './SmartPostCard'
import { create, props } from '@stylexjs/stylex'
import { queryKeys } from '../constants/query-keys'
import { ApiError } from '../api/apiError'
import { ErrorCodes } from '../client'
import { NotPostFoundPage } from '../layout/pages/PostNotFoundPage'
import { routes } from '../router/routes'

type SinglePostPageProps = {
  postId: string
  channelSlug: string
}

const styles = create({
  container: {
    maxWidth: '888px',
    width: '100%',
    margin: '0 auto',
  },
})

export const SinglePostPage: React.FC<SinglePostPageProps> = ({
  postId,
  channelSlug,
}) => {
  const { postApi } = useApi()
  const [isPostNotFound, setIsPostNotFound] = useState(false)

  const {
    data: post,
    isLoading,
    isError,
  } = useQuery({
    queryKey: queryKeys.posts.single(postId),
    queryFn: () => postApi.postPostIdGet({ postId }),
    enabled: !!postId,
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (error instanceof ApiError && error.code === ErrorCodes.PostNotFound) {
        setIsPostNotFound(true)
      }
    },
  })

  if (isLoading) return <LoadingScreen />

  if (isPostNotFound) {
    return <NotPostFoundPage slug={channelSlug} />
  }

  if (!post || isError) {
    return <Navigate to={routes.channel.homeShort(channelSlug)} replace />
  }

  return (
    <div {...props(styles.container)}>
      <SmartPostCard post={post} isPostCardsRestricted />
    </div>
  )
}
