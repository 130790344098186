import type { SVGProps } from 'react'

export const Video: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="fi_5346453"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={stroke}
  >
    <path d="M21.78857,6.34131a1.50782,1.50782,0,0,0-1.52783-.02442L16.5,9.02411V8A2.50294,2.50294,0,0,0,14,5.5H4A2.50294,2.50294,0,0,0,1.5,8v8A2.50294,2.50294,0,0,0,4,18.5H14A2.50294,2.50294,0,0,0,16.5,16V14.97424l3.82959,2.7489A1.50023,1.50023,0,0,0,22.5,16.38135V7.61719A1.49105,1.49105,0,0,0,21.78857,6.34131ZM15.5,16A1.50164,1.50164,0,0,1,14,17.5H4A1.50164,1.50164,0,0,1,2.5,16V8A1.50164,1.50164,0,0,1,4,6.5H14A1.50164,1.50164,0,0,1,15.5,8Zm6,.38135a.51685.51685,0,0,1-.65527.48877L16.5,13.74365V10.25586l4.27637-3.08594A.50018.50018,0,0,1,21.5,7.61719Z"></path>
  </svg>
)
