import React from 'react'
import useLanguage from '../translations/useLanguage'
import { create, props } from '@stylexjs/stylex'
import { fonts } from '../styles/font-styles'
import { color, fontWeight, spacing } from '../styles/token.stylex'

const styles = create({
  badge: {
    backgroundColor: color.primary,
    color: color.avatarLiveTextColor,
    borderRadius: '0.3125rem',
    padding: `${spacing.xxxSmall} ${spacing.xSmall}`,
    maxWidth: 'min-content',
    zIndex: 99,
    transform: {
      default: 'scale(1)',
      ':hover': 'scale(1.05)',
    },
    ':hover': {
      fontWeight: fontWeight.semiBold,
    },
    transition: 'transform 0.3s ease',
  },
  bottom: {
    position: 'absolute',
    bottom: '-0.66rem',
    left: '50%',
    transform: 'translateX(-50%)',
  },
})

type LiveBadgeProps = {
  variant?: 'bottom'
}

export const LiveBadge: React.FC<LiveBadgeProps> = ({ variant }) => {
  const { t } = useLanguage()
  return (
    <div
      {...props(
        styles.badge,
        fonts.extraSmallRegular,
        variant && styles[variant]
      )}
    >
      {t('shared.general.live')}
    </div>
  )
}
