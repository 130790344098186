import { Heart } from '../components/icons/Heart'

export const LikeIcon: React.FC<{ isLiked: boolean; disabled: boolean }> = ({
  isLiked,
  disabled,
}) => (
  <Heart
    variant={isLiked ? 'filled' : 'outline'}
    stroke={isLiked && !disabled ? 'gold' : 'currentColor'}
  />
)
