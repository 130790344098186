import { create, props } from '@stylexjs/stylex'
import { Button } from '../components/Button'
import useLanguage from '../translations/useLanguage'
import { useAuth0 } from '@auth0/auth0-react'
import { spacing } from '../styles/token.stylex'

const styles = create({
  container: {
    display: 'flex',
    gap: spacing.small,
  },
})

export const LoginAndRegisterButtons: React.FC = () => {
  const { t, languageCode } = useLanguage()
  const { loginWithRedirect } = useAuth0()

  const handleAuth = (hint?: 'signup') =>
    loginWithRedirect({
      authorizationParams: {
        screen_hint: hint,
        ui_locales: languageCode,
      },
      appState: { returnTo: window.location.pathname + window.location.search },
    })

  return (
    <div {...props(styles.container)}>
      <Button onClick={() => handleAuth()}>{t('auth.actions.login')}</Button>
      <Button variant="cta" onClick={() => handleAuth('signup')}>
        {t('auth.actions.signup')}
      </Button>
    </div>
  )
}
