import { defineVars } from '@stylexjs/stylex'

export const elementSizes = defineVars({
  headerHeight: '3.75rem',
  footerHeight: '3.75rem',
  sidebarWidth: '275px',
  mobileAvatarSize: '4.5rem',
  desktopAvatarSize: '6rem',
})

export const spacing = defineVars({
  xxxSmall: '.125rem', //2px
  xxSmall: '.25rem', //4px
  xSmall: '.375rem', //6px
  extraSmall: '.5rem', //8px
  small: '.75rem', //12px
  normal: '1rem', //16px
  large: '1.5rem', //24px
  larger: '1.75rem', //28px
  extraLarge: '2rem', //32px
  extraLarger: '2.5rem', //40px
  xxl: '3rem', //48px
})

export const lineHeight = defineVars({
  xxxl: '8.5rem', //136px
  xxl: '2rem', //32px
  extraLarge: '1.875rem', //30px
  large: '1.75rem', //28px
  normal: '1.5rem', //24px
  small: '1.375rem', //22px
  extraSmall: '1.125rem', //18px
  xxSmall: '1rem', //16px
})

export const fontSize = defineVars({
  xxxl: '8rem', //128px
  extraLarger: '1.875rem', //30px
  xxl: '1.5rem', //24px
  extraLarge: '1.25rem', //20px
  large: '1.125rem', //18px
  normal: '1rem', //16px
  small: '.875rem', //14px
  extraSmall: '.75rem', //12px
  xxSmall: '.625rem', //10px
  xxxSmall: '.625rem', //8px
})

export const fontWeight = defineVars({
  regular: '400',
  medium: '500',
  semiBold: '600',
})

// grays
const codGray = '#101010'
const codGrayLight = '#181818'
const mineShaft = '#2f2f2f'
const mineShaftDark = '#202020'
const black = '#000'
const dustyGray = '#9a9a9a'
const white = '#fff'
const manatee = '#928c9e'
const deepGray = '#191919'
const woodsmoke = '#0c0e0f'

const lightGray = '#ECECEC'

// yellows
const cornHarvest = '#877308'
const gold = '#ffd700'

// reds
const claret = '#701527'
const torchRed = '#ff1d49'
const torchRedDark = '#c72047'
const cornFlowerLilac = '#ffaaaa'

// blues
const brightTurquoise = '#2bd5fa'

// greens
const pasterGreen = '#53e773'

// purples
const helitrope = '#9975ff'

export const color = defineVars({
  primary: torchRed,
  primaryHighlight: cornFlowerLilac,
  primaryDark: claret,

  error: torchRed,

  buttonBackgroundDefault: mineShaft,
  buttonBackgroundHover: mineShaftDark,

  buttonTextColorDefault: white,
  buttonTextColorDisabled: dustyGray,

  inputBackgroundDefault: codGray,
  inputBackgroundDisabled: codGrayLight,

  inputColorDefault: white,
  inputColorActive: white,

  inputOutlineHover: cornHarvest,
  inputOutlineFocus: gold,

  linkColorDefault: white,
  linkColorHover: gold,
  linkColorActive: gold,
  linkColorFocus: white,
  linkColorDisabled: dustyGray,

  textLinkColorDefault: gold,
  textLinkColorFooterDefault: dustyGray,
  textLinkColorHover: white,
  textLinkColorActive: white,
  textLinkColorDisabled: dustyGray,

  textLinkGrayVariantDefault: dustyGray,
  textLinkGrayVariantHovered: gold,
  textLinkWhiteVariantDefault: white,
  textLinkWhiteVariantHovered: gold,

  linkOutlineFocus: gold,

  iconButtonColorDefault: manatee,
  iconButtonColorHover: gold,
  iconButtonColorActive: gold,
  iconButtonColorFocus: manatee,
  iconButtonColorDisabled: dustyGray,

  iconButtonColorOutlineFocus: gold,

  avatarImageBackground: codGray,
  avatarImageBorder: torchRed,

  displayNameTextColor: white,

  postCardPublishDateTextColor: dustyGray,
  postCardTextColor: white,
  postCardLikeCountTextColor: dustyGray,

  unlockedCardTextColor: white,
  unlockedCardPriceColor: gold,
  unlockedCardBackgroundColor: codGrayLight,

  contextMenuBackgroundColor: codGray,

  contextMenuItemTextColor: white,
  contextMenuItemHoverTextColor: gold,
  contextMenuItemBackgroundColor: mineShaft,

  avatarLiveTextColor: white,

  tabTextColorDefault: dustyGray,
  tabTextColorSelected: white,

  tagBackgroundColor: mineShaft,
  tagTextColor: white,
  tagDisabledTextColor: dustyGray,

  tagButtonBackgroundColor: mineShaft,
  tagButtonTextColor: dustyGray,
  tagButtonActiveTextColor: white,
  tagButtonHoverTextColor: white,

  tagButtonDisabledBackgroundColor: codGray,
  tagButtonDisabledTextColor: dustyGray,

  notificationItemMessage: white,
  notificationItemTime: dustyGray,
  notificationItemSeparator: mineShaft,

  successBackgroundColor: codGrayLight,
  successTextColor: white,
  errorBackgroundColor: codGrayLight,
  errorTextColor: white,
  defaultToastBackgroundColor: codGrayLight,
  defaultToastTextColor: white,
  infoToastBackgroundColor: codGrayLight,
  infoToastTextColor: white,

  unlockableItemColor: white,
  unlockableItemBackground: codGrayLight,

  radioButtonBorderDisabled: dustyGray,
  radioButtonLabelDefault: dustyGray,
  radioButtonLabelSelected: white,
  radioButtonLabelDisabled: dustyGray,
  radioButtonDisabled: dustyGray,

  dialogBorder: mineShaft,
  dialogBackground: codGrayLight,
  dialogDefaultText: white,

  desktopNavbarBackground: codGray,
  tapBarBackground: codGray,
  tapBarBorder: mineShaft,

  dividerBackground: dustyGray,

  //neutrals - do not use only for UI kit
  codGray,
  codGrayLight,
  mineShaft,
  mineShaftDark,
  dustyGray,
  white,
  black,
  manatee,
  woodsmoke,
  deepGray,
  lightGray,

  //main - do not use only for UI kit
  gold,
  torchRed,
  torchRedDark,
  brightTurquoise,
  pasterGreen,
  helitrope,
})
