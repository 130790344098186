import React from 'react'
import { color, spacing } from '../styles/token.stylex'
import { create, props } from '@stylexjs/stylex'
import useLanguage from '../translations/useLanguage'
import { titleFonts } from '../styles/font-styles'
import { Post } from '../components/icons/Post'
import { routes } from '../router/routes'
import { useNavigate } from 'react-router-dom'
import { Button } from '../components/Button'

type EmptyPostsProps = {
  isOwner?: boolean
}

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.large,
    borderRadius: spacing.extraSmall,
    backgroundColor: color.deepGray,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: spacing.extraLarge,
    minHeight: '300px',
  },
  logo: {
    color: color.gold,
  },
  title: {
    textAlign: 'center',
  },
  button: {
    paddingTop: spacing.large,
  },
})

export const EmptyPosts: React.FC<EmptyPostsProps> = ({ isOwner }) => {
  const { t } = useLanguage()
  const navigate = useNavigate()

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.logo)}>
        <Post width="4rem" height="4rem" />
      </div>
      <div {...props(styles.title, titleFonts.largeSemiBold)}>
        {isOwner ? t('post.empty.owner') : t('post.empty.user')} <br />
        {isOwner && t('post.empty.ownerSecondPart')}
      </div>
      {isOwner && (
        <div {...props(styles.button)}>
          <Button onClick={() => navigate(routes.post.create)} variant="cta">
            {t('post.empty.createPost')}
          </Button>
        </div>
      )}
    </div>
  )
}
