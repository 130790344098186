import { useMemo, useState } from 'react'
import { useApi } from '../../api/ApiContext'
import useLanguage, {
  SUPPORTED_LANGUAGES,
} from '../../translations/useLanguage'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import {
  DepositRequest,
  DepositResponse,
  GetChannelInfoResponse,
  PaymentType,
} from '../../client'
import { queryKeys } from '../../constants/query-keys'
import {
  computeVatDetails,
  getDefaultPaymentMethod,
  getPaymentMethodOptions,
} from '../../post/tip/utils'
import { create, props } from '@stylexjs/stylex'
import { FullCreatorInfo } from '../../components/FullCreatorInfo'
import { Button } from '../../components/Button'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { ReactSelect } from '../../components/input/Select'
import { color, spacing } from '../../styles/token.stylex'
import { routes } from '../../router/routes'
import { expirationDate } from '../subscription-price/utils'
import { fonts } from '../../styles/font-styles'
import { TextLink } from '../../components/TextLink'
import { sharedStyles } from '../../common/styles'

type PaidSubscriptionModalProps = {
  channel: GetChannelInfoResponse
}

type SubscriptionFormValues = {
  paymentMethod: string
}

export const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '320px',
    position: 'relative',
  },
  actionsContainer: {
    display: 'flex',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  },
  hidden: {
    display: 'none',
  },
  priceDetailed: {
    color: color.dustyGray,
  },
  info: {
    listStyleType: 'disc',
    listStylePosition: 'outside',
    marginLeft: spacing.small,
    color: color.dustyGray,
  },
})

export const PaidSubscriptionModalContent: React.FC<
  PaidSubscriptionModalProps
> = ({ channel }) => {
  const { t, formatCurrency, languageCode, formatDate } = useLanguage()
  const { paymentsApi } = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const { control, handleSubmit, setValue, watch, formState } =
    useForm<SubscriptionFormValues>({
      defaultValues: { paymentMethod: '' },
      mode: 'onChange',
    })

  const { mutateAsync } = useMutation<DepositResponse, Error, DepositRequest>({
    mutationFn: async (requestParameters: DepositRequest) => {
      return paymentsApi.paymentsPayPost({ depositRequest: requestParameters })
    },
  })

  const { data, isFetching } = useQuery({
    queryKey: [
      queryKeys.payments.default,
      channel.subscriptionOptions.subscribePrice.amount,
    ],
    queryFn: () =>
      paymentsApi.paymentsMethodsVatGet({
        type: PaymentType.Subscription,
        price: Math.round(channel.subscriptionOptions.subscribePrice.amount),
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess: (fetchedData) => {
      if (fetchedData.paymentMethods?.length && !watch('paymentMethod')) {
        const defaultMethod = getDefaultPaymentMethod(fetchedData)
        setValue('paymentMethod', defaultMethod, { shouldValidate: true })
      }
    },
  })

  const { vatAmount, totalAmount } = useMemo(() => {
    return computeVatDetails(
      data,
      watch('paymentMethod'),
      Number(channel.subscriptionOptions.subscribePrice.amount)
    )
  }, [data, channel.subscriptionOptions.subscribePrice.amount, watch])

  const onSubmit = async (formData: SubscriptionFormValues) => {
    setIsLoading(true)
    try {
      const language = SUPPORTED_LANGUAGES.find(
        (lang) => lang.code === languageCode
      )
      if (!language) {
        console.error('Language not found:', languageCode)
        setIsLoading(false)
        return
      }

      const requestBody: DepositRequest = {
        language: language.cultureCode,
        depositMethod: formData.paymentMethod,
        redirectUrl: window.location.href,
        currency: channel.subscriptionOptions.subscribePrice.currency,
        paymentType: PaymentType.Subscription,
        recipientUserId: channel.user.id,
      } as DepositRequest

      const response = await mutateAsync(requestBody)
      const paymentUrl = response?.paymentPageSession?.paymentPageUrl

      if (!paymentUrl) {
        console.error('Payment URL not found in response.')
        setIsLoading(false)
        return
      }

      window.location.href = paymentUrl
    } catch (error) {
      console.error('Payment submission failed:', error)
      setIsLoading(false)
    }
  }

  const paymentMethodOptions = data
    ? getPaymentMethodOptions(data.paymentMethods, watch('paymentMethod'), t)
    : []

  return (
    <div {...props(styles.container)}>
      <FullCreatorInfo
        displayName={channel.user.displayName}
        fullName={channel.user.displayName}
        isVerified={channel.isVerified}
        profilePictureUrl={channel.user.profilePicture?.source}
      />
      <form onSubmit={handleSubmit(onSubmit)} {...props(styles.formContainer)}>
        <div>
          <p {...props(fonts.smallSemiBold)}>
            {t('subscription.chargeMessage', {
              amount: formatCurrency(totalAmount, {
                currency: channel.subscriptionOptions.subscribePrice.currency,
              }),
            })}
          </p>
          <span {...props(fonts.smallRegular, styles.priceDetailed)}>
            {t('subscription.priceBreakdown', {
              basePrice: formatCurrency(
                channel.subscriptionOptions.subscribePrice.amount,
                {
                  currency: channel.subscriptionOptions.subscribePrice.currency,
                }
              ),
              vatAmount: formatCurrency(vatAmount, {
                currency: channel.subscriptionOptions.subscribePrice.currency,
              }),
            })}
          </span>
        </div>

        <Controller
          name="paymentMethod"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              required
              id="subscription-payment-method-select"
              label={t('subscription.paymentMethodLabel')}
              placeholder={t('subscription.paymentMethodPlaceholder')}
              options={paymentMethodOptions}
              value={field.value}
            />
          )}
        />
        <div {...props(styles.info, fonts.extraSmallRegular)}>
          <ul>
            <li>{t('subscription.fullAccess')}</li>
            <li>
              {t('subscription.expirationMessage', {
                date: formatDate(expirationDate),
              })}
            </li>
            <li>{t('subscription.manualRenewal')}</li>
            <li>
              {t('subscription.terms')}{' '}
              <TextLink
                to={routes.termsOfService}
                font="extraSmallRegular"
                isExternal
              >
                {t('subscription.termsOfService')}
              </TextLink>
            </li>
          </ul>
        </div>
        <div {...props(styles.actionsContainer)}>
          <Button
            type="submit"
            variant="cta"
            disabled={!formState.isValid || isFetching}
          >
            {t('subscription.confirm')}
          </Button>
        </div>
      </form>
      <div {...props(!isLoading ? styles.hidden : sharedStyles.loading)}>
        <LoadingIndicator width={40} height={40} />
      </div>
    </div>
  )
}
