import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ErrorBoundary } from 'react-error-boundary'
import { Router } from './router/Router'
import { Provider } from 'jotai'
import { store } from './store'
import { ReactQueryDevtools } from 'react-query/devtools'
import React from 'react'
import { GeneralErrorPage } from './layout/pages/GeneralErrorPage'
import { UnderConstructionDialogProvider } from './style-guide/components/under-construction/UnderConstructionContextProvider'
import { BrowserRouter } from 'react-router-dom'
import { Auth0ProviderWithNavigate } from './auth/Auth0ProviderWithNavigate'
import { UserContextProvider } from './auth/UserContextProvider'
import { StyledToastContainer } from './components/toast/ToastContainer'
import { ApiProvider } from './api/ApiContext'
import { QueryClientWithDefaultErrorHandlingProvider } from './QueryClient'

const App: React.FC = () => {
  return (
    <QueryClientWithDefaultErrorHandlingProvider>
      <Provider store={store}>
        <StyledToastContainer />
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <ApiProvider>
              <UserContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
                <UnderConstructionDialogProvider>
                  <ErrorBoundary FallbackComponent={GeneralErrorPage}>
                    <RadixTooltip.Provider>
                      <Router />
                    </RadixTooltip.Provider>
                  </ErrorBoundary>
                </UnderConstructionDialogProvider>
              </UserContextProvider>
            </ApiProvider>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </Provider>
    </QueryClientWithDefaultErrorHandlingProvider>
  )
}

export default App
