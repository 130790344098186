import { LanguageKey } from '../../../translations/useLanguage'

export function paidPostValidator(
  data: { price: string },
  translate: (
    key: LanguageKey,
    replacements?: Record<string, string>
  ) => string,
  formatCurrency: (amount: number, options: { currency: string }) => string,
  minimumPrice: number,
  maximumPrice: number
) {
  const price = parseInt(data.price)

  if (isNaN(price) || price === 0) {
    return { values: data, errors: {} }
  }

  if (price > maximumPrice) {
    return {
      values: data,
      errors: {
        price: {
          message: translate('post.paidPost.maximumPrice', {
            maximumPrice: formatCurrency(maximumPrice, { currency: 'JPY' }),
          }),
        },
      },
    }
  }

  if (price < minimumPrice) {
    return {
      values: data,
      errors: {
        price: {
          message: translate('post.paidPost.minimumPrice', {
            minimumPrice: formatCurrency(minimumPrice, { currency: 'JPY' }),
          }),
        },
      },
    }
  }

  return { values: data, errors: {} }
}
