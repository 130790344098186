/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepositMethod } from './DepositMethod';
import {
    DepositMethodFromJSON,
    DepositMethodFromJSONTyped,
    DepositMethodToJSON,
} from './DepositMethod';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';
import type { TipSource } from './TipSource';
import {
    TipSourceFromJSON,
    TipSourceFromJSONTyped,
    TipSourceToJSON,
} from './TipSource';

/**
 * Represents the request for initiating a deposit.
 * @export
 * @interface DepositRequest
 */
export interface DepositRequest {
    /**
     * Language for the payment page (e.g., "en-Us", "ja").
     * @type {string}
     * @memberof DepositRequest
     */
    language: string;
    /**
     * 
     * @type {DepositMethod}
     * @memberof DepositRequest
     */
    depositMethod: DepositMethod;
    /**
     * The URL to redirect the user after completing the payment (e.g., https://beestream.com/demouser).
     * @type {string}
     * @memberof DepositRequest
     */
    redirectUrl: string;
    /**
     * 
     * @type {number}
     * @memberof DepositRequest
     */
    amount?: number;
    /**
     * Currency Code - ISO 4217
     * @type {string}
     * @memberof DepositRequest
     */
    currency?: string | null;
    /**
     * 
     * @type {PaymentType}
     * @memberof DepositRequest
     */
    paymentType: PaymentType;
    /**
     * Optional text for the payment (e.g., tip message).
     * @type {string}
     * @memberof DepositRequest
     */
    text?: string | null;
    /**
     * The user ID of the recipient (for tipping or subscription)
     * @type {number}
     * @memberof DepositRequest
     */
    recipientUserId?: number;
    /**
     * 
     * @type {TipSource}
     * @memberof DepositRequest
     */
    tipSource?: TipSource;
    /**
     * Associated post ID, if applicable (for tips tied to posts or PPV purchase).
     * @type {string}
     * @memberof DepositRequest
     */
    postId?: string | null;
}

/**
 * Check if a given object implements the DepositRequest interface.
 */
export function instanceOfDepositRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "depositMethod" in value;
    isInstance = isInstance && "redirectUrl" in value;
    isInstance = isInstance && "paymentType" in value;

    return isInstance;
}

export function DepositRequestFromJSON(json: any): DepositRequest {
    return DepositRequestFromJSONTyped(json, false);
}

export function DepositRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepositRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': json['language'],
        'depositMethod': DepositMethodFromJSON(json['depositMethod']),
        'redirectUrl': json['redirectUrl'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'paymentType': PaymentTypeFromJSON(json['paymentType']),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'recipientUserId': !exists(json, 'recipientUserId') ? undefined : json['recipientUserId'],
        'tipSource': !exists(json, 'tipSource') ? undefined : TipSourceFromJSON(json['tipSource']),
        'postId': !exists(json, 'postId') ? undefined : json['postId'],
    };
}

export function DepositRequestToJSON(value?: DepositRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': value.language,
        'depositMethod': DepositMethodToJSON(value.depositMethod),
        'redirectUrl': value.redirectUrl,
        'amount': value.amount,
        'currency': value.currency,
        'paymentType': PaymentTypeToJSON(value.paymentType),
        'text': value.text,
        'recipientUserId': value.recipientUserId,
        'tipSource': TipSourceToJSON(value.tipSource),
        'postId': value.postId,
    };
}

