/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddPostRequest,
  ApiErrorResponse,
  GetLikeResponse,
  GetPostResponse,
  GetPostResponsePagedApiResponse,
} from '../models/index';
import {
    AddPostRequestFromJSON,
    AddPostRequestToJSON,
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetLikeResponseFromJSON,
    GetLikeResponseToJSON,
    GetPostResponseFromJSON,
    GetPostResponseToJSON,
    GetPostResponsePagedApiResponseFromJSON,
    GetPostResponsePagedApiResponseToJSON,
} from '../models/index';

export interface PostChannelChannelIdGetRequest {
    channelId: string;
    pageNumber?: number;
    pageSize?: number;
}

export interface PostGetRequest {
    pageNumber?: number;
    pageSize?: number;
}

export interface PostPostRequest {
    addPostRequest?: AddPostRequest;
}

export interface PostPostIdDeleteRequest {
    postId: string;
}

export interface PostPostIdGetRequest {
    postId: string;
}

export interface PostPostIdLikePostRequest {
    postId: string;
}

/**
 * 
 */
export class PostApi extends runtime.BaseAPI {

    /**
     * Get a paged list of posts of a channel.
     */
    async postChannelChannelIdGetRaw(requestParameters: PostChannelChannelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPostResponsePagedApiResponse>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling postChannelChannelIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/post/channel/{channelId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPostResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     * Get a paged list of posts of a channel.
     */
    async postChannelChannelIdGet(requestParameters: PostChannelChannelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPostResponsePagedApiResponse> {
        const response = await this.postChannelChannelIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postGetRaw(requestParameters: PostGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPostResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/post`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPostResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async postGet(requestParameters: PostGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPostResponsePagedApiResponse> {
        const response = await this.postGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a new post with images and videos.
     */
    async postPostRaw(requestParameters: PostPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPostResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPostRequestToJSON(requestParameters.addPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPostResponseFromJSON(jsonValue));
    }

    /**
     * Add a new post with images and videos.
     */
    async postPost(requestParameters: PostPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPostResponse> {
        const response = await this.postPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a post by its ID.
     */
    async postPostIdDeleteRaw(requestParameters: PostPostIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling postPostIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/post/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a post by its ID.
     */
    async postPostIdDelete(requestParameters: PostPostIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postPostIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a post by its ID, including its associated images and videos.
     */
    async postPostIdGetRaw(requestParameters: PostPostIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPostResponse>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling postPostIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/post/{postId}`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPostResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a post by its ID, including its associated images and videos.
     */
    async postPostIdGet(requestParameters: PostPostIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPostResponse> {
        const response = await this.postPostIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Like or unlike a post by its Id.
     */
    async postPostIdLikePostRaw(requestParameters: PostPostIdLikePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLikeResponse>> {
        if (requestParameters.postId === null || requestParameters.postId === undefined) {
            throw new runtime.RequiredError('postId','Required parameter requestParameters.postId was null or undefined when calling postPostIdLikePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/post/{postId}/like`.replace(`{${"postId"}}`, encodeURIComponent(String(requestParameters.postId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLikeResponseFromJSON(jsonValue));
    }

    /**
     * Like or unlike a post by its Id.
     */
    async postPostIdLikePost(requestParameters: PostPostIdLikePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLikeResponse> {
        const response = await this.postPostIdLikePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
