/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepositStatus } from './DepositStatus';
import {
    DepositStatusFromJSON,
    DepositStatusFromJSONTyped,
    DepositStatusToJSON,
} from './DepositStatus';
import type { GetDepositPostResponse } from './GetDepositPostResponse';
import {
    GetDepositPostResponseFromJSON,
    GetDepositPostResponseFromJSONTyped,
    GetDepositPostResponseToJSON,
} from './GetDepositPostResponse';
import type { GetDepositUserResponse } from './GetDepositUserResponse';
import {
    GetDepositUserResponseFromJSON,
    GetDepositUserResponseFromJSONTyped,
    GetDepositUserResponseToJSON,
} from './GetDepositUserResponse';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface GetDepositHistoryResponse
 */
export interface GetDepositHistoryResponse {
    /**
     * The amount of the deposit.
     * @type {number}
     * @memberof GetDepositHistoryResponse
     */
    amount: number;
    /**
     * The percentage of creator earnings retained by the platform as commission. 
     * This is expressed as a decimal, where 0.2m represents 20%.
     * @type {number}
     * @memberof GetDepositHistoryResponse
     */
    vatPercentage: number;
    /**
     * The amount of VAT added to Casino.API.Contracts.V1.Responses.Payment.GetDepositHistoryResponse.Amount.
     * @type {number}
     * @memberof GetDepositHistoryResponse
     */
    vatAmount: number;
    /**
     * The date and time the deposit was created.
     * @type {Date}
     * @memberof GetDepositHistoryResponse
     */
    createdAt: Date;
    /**
     * The currency of the deposit.
     * @type {string}
     * @memberof GetDepositHistoryResponse
     */
    currency: string;
    /**
     * 
     * @type {GetDepositUserResponse}
     * @memberof GetDepositHistoryResponse
     */
    user: GetDepositUserResponse;
    /**
     * The ID of the deposit.
     * @type {number}
     * @memberof GetDepositHistoryResponse
     */
    id: number;
    /**
     * 
     * @type {PaymentType}
     * @memberof GetDepositHistoryResponse
     */
    paymentType: PaymentType;
    /**
     * 
     * @type {DepositStatus}
     * @memberof GetDepositHistoryResponse
     */
    status: DepositStatus;
    /**
     * a created id of the transaction. not a foreign key, it serves debugging purposes
     * @type {string}
     * @memberof GetDepositHistoryResponse
     */
    orderId: string;
    /**
     * 
     * @type {GetDepositPostResponse}
     * @memberof GetDepositHistoryResponse
     */
    post?: GetDepositPostResponse;
}

/**
 * Check if a given object implements the GetDepositHistoryResponse interface.
 */
export function instanceOfGetDepositHistoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "vatPercentage" in value;
    isInstance = isInstance && "vatAmount" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "paymentType" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "orderId" in value;

    return isInstance;
}

export function GetDepositHistoryResponseFromJSON(json: any): GetDepositHistoryResponse {
    return GetDepositHistoryResponseFromJSONTyped(json, false);
}

export function GetDepositHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDepositHistoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'vatPercentage': json['vatPercentage'],
        'vatAmount': json['vatAmount'],
        'createdAt': (new Date(json['createdAt'])),
        'currency': json['currency'],
        'user': GetDepositUserResponseFromJSON(json['user']),
        'id': json['id'],
        'paymentType': PaymentTypeFromJSON(json['paymentType']),
        'status': DepositStatusFromJSON(json['status']),
        'orderId': json['orderId'],
        'post': !exists(json, 'post') ? undefined : GetDepositPostResponseFromJSON(json['post']),
    };
}

export function GetDepositHistoryResponseToJSON(value?: GetDepositHistoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'vatPercentage': value.vatPercentage,
        'vatAmount': value.vatAmount,
        'createdAt': (value.createdAt.toISOString()),
        'currency': value.currency,
        'user': GetDepositUserResponseToJSON(value.user),
        'id': value.id,
        'paymentType': PaymentTypeToJSON(value.paymentType),
        'status': DepositStatusToJSON(value.status),
        'orderId': value.orderId,
        'post': GetDepositPostResponseToJSON(value.post),
    };
}

