import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { DisplayName } from './DisplayName'
import { AvatarImage } from './avatars/AvatarImage'
import { fonts } from '../styles/font-styles'
import { FullCreator } from './types'
import { TextLink } from './TextLink'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    gap: spacing.small,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.xxxSmall,
      [largeScreen]: spacing.xxSmall,
    },
  },
  displayName: {
    color: color.dustyGray,
  },
})

export const FullCreatorInfo: React.FC<FullCreator> = ({
  profilePictureUrl,
  fullName,
  displayName,
  isVerified,
  profileUrl,
  hideImage = false,
}) => (
  <div {...props(styles.container)}>
    {!hideImage && (
      <AvatarImage
        src={profilePictureUrl}
        alt={`${displayName}'s profile`}
        url={profileUrl}
        variant="medium"
      />
    )}
    <div {...props(styles.nameContainer)}>
      <DisplayName
        text={fullName}
        url={profileUrl}
        font="normalSemiBoldCreatorInfo"
        verification={isVerified ? 'simple' : undefined}
      />
      <div {...props(styles.displayName, fonts.extraSmallRegular)}>
        <TextLink to={profileUrl} underline variant="gray">
          {`@${displayName}`}
        </TextLink>
      </div>
    </div>
  </div>
)
