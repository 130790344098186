import { create, props } from '@stylexjs/stylex'
import { useNavigate } from 'react-router-dom'
import { TextLink } from '../components/TextLink'
import { ArrowLeft } from '../components/icons/ArrowLeft'
import { spacing } from '../styles/token.stylex'
import { titleFonts } from '../styles/font-styles'
import { responsiveStyles } from './navbar/styles'

const styles = create({
  menuTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
  },
  phone: {
    paddingLeft: spacing.normal,
    paddingBlock: `${spacing.normal}`,
  },
  desktop: {
    paddingBlock: `${spacing.large}`,
  },
})

export const PageHeader: React.FC<React.PropsWithChildren> = ({
  children: title,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <div {...props(responsiveStyles.phoneFlex, styles.phone)}>
        <TextLink onClick={() => navigate('/')} variant="white">
          <div {...props(styles.menuTitleContainer)}>
            <ArrowLeft />
            <div {...props(titleFonts.largeSemiBold)}>{title}</div>
          </div>
        </TextLink>
      </div>
      <div {...props(responsiveStyles.desktopFlex, styles.desktop)}>
        <div {...props(titleFonts.xlSemiBold)}>{title}</div>
      </div>
    </>
  )
}
