import { createContext, useContext } from 'react'
import {
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
} from '../../client'

export type Channel = {
  isLoading: boolean
  isError: boolean
  channel?: GetChannelInfoResponse
  reloadChannel: () => void
  reloadMe: () => void
  me?: GetChannelLoggedInUserResponse
}

export const ChannelContext = createContext<Channel>({
  isLoading: false,
  isError: false,
  reloadChannel: () => {},
  reloadMe: () => {},
})

export const useChannelContext = () => useContext(ChannelContext)
