import type { SVGProps } from 'react'

export const Eye: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.96086 9.00006C6.96086 9.52221 7.16828 10.023 7.53749 10.3922C7.9067 10.7614 8.40746 10.9688 8.92961 10.9688C9.45175 10.9688 9.95251 10.7614 10.3217 10.3922C10.6909 10.023 10.8984 9.52221 10.8984 9.00006C10.8984 8.47792 10.6909 7.97716 10.3217 7.60794C9.95251 7.23873 9.45175 7.03131 8.92961 7.03131C8.40746 7.03131 7.9067 7.23873 7.53749 7.60794C7.16828 7.97716 6.96086 8.47792 6.96086 9.00006ZM16.562 8.54655C14.8956 5.03619 12.3767 3.26959 8.99992 3.26959C5.6214 3.26959 3.10422 5.03619 1.43781 8.5483C1.37097 8.68984 1.3363 8.84442 1.3363 9.00094C1.3363 9.15746 1.37097 9.31204 1.43781 9.45358C3.10422 12.9639 5.62316 14.7305 8.99992 14.7305C12.3784 14.7305 14.8956 12.9639 16.562 9.45182C16.6974 9.16705 16.6974 8.83658 16.562 8.54655ZM8.92961 12.0938C7.22101 12.0938 5.83586 10.7087 5.83586 9.00006C5.83586 7.29147 7.22101 5.90631 8.92961 5.90631C10.6382 5.90631 12.0234 7.29147 12.0234 9.00006C12.0234 10.7087 10.6382 12.0938 8.92961 12.0938Z"
      fill={fill}
    ></path>
  </svg>
)
