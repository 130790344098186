/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetSubscriptionUserResponse } from './GetSubscriptionUserResponse';
import {
    GetSubscriptionUserResponseFromJSON,
    GetSubscriptionUserResponseFromJSONTyped,
    GetSubscriptionUserResponseToJSON,
} from './GetSubscriptionUserResponse';

/**
 * 
 * @export
 * @interface GetSubscriptionResponse
 */
export interface GetSubscriptionResponse {
    /**
     * The id of the subscription.
     * @type {number}
     * @memberof GetSubscriptionResponse
     */
    id: number;
    /**
     * The price of the subscription, when the subscripton was created.
     * @type {number}
     * @memberof GetSubscriptionResponse
     */
    price: number;
    /**
     * True if the subscription is active (not expired, or free forever).
     * @type {boolean}
     * @memberof GetSubscriptionResponse
     */
    isActive: boolean;
    /**
     * True if the subscription is free forever.
     * @type {boolean}
     * @memberof GetSubscriptionResponse
     */
    isFree: boolean;
    /**
     * The date and time the subscription was started.
     * @type {Date}
     * @memberof GetSubscriptionResponse
     */
    startedAt: Date;
    /**
     * The date and time the subscription expires.
     * @type {Date}
     * @memberof GetSubscriptionResponse
     */
    expiresAt: Date;
    /**
     * 
     * @type {GetSubscriptionUserResponse}
     * @memberof GetSubscriptionResponse
     */
    user: GetSubscriptionUserResponse;
}

/**
 * Check if a given object implements the GetSubscriptionResponse interface.
 */
export function instanceOfGetSubscriptionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "isFree" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "expiresAt" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GetSubscriptionResponseFromJSON(json: any): GetSubscriptionResponse {
    return GetSubscriptionResponseFromJSONTyped(json, false);
}

export function GetSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSubscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'price': json['price'],
        'isActive': json['isActive'],
        'isFree': json['isFree'],
        'startedAt': (new Date(json['startedAt'])),
        'expiresAt': (new Date(json['expiresAt'])),
        'user': GetSubscriptionUserResponseFromJSON(json['user']),
    };
}

export function GetSubscriptionResponseToJSON(value?: GetSubscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'price': value.price,
        'isActive': value.isActive,
        'isFree': value.isFree,
        'startedAt': (value.startedAt.toISOString()),
        'expiresAt': (value.expiresAt.toISOString()),
        'user': GetSubscriptionUserResponseToJSON(value.user),
    };
}

