import React from 'react'
import { useChannelContext } from '../../channel/context/channel-context'
import { CreatePost } from './CreatePost'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'

export const SmartCreatePostPage: React.FC = () => {
  const { channel } = useChannelContext()

  if (!channel) return <LoadingIndicator />

  return <CreatePost channel={channel} />
}
