import { create } from '@stylexjs/stylex'
import { spacing } from '../../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

export const tabStyles = create({
  contentContainer: {
    padding: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
  },
})
