import type { SVGProps } from 'react'

export const ThinLock: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
  >
    <path
      d="M16 71.2V48.8C16 44.3192 16 42.0796 16.872 40.368C17.6389 38.8629 18.8619 37.6389 20.3672 36.872C22.0785 36 24.3204 36 28.8008 36H67.2008C71.6812 36 73.92 36 75.6312 36.872C77.1364 37.6389 78.362 38.8629 79.1288 40.368C80.0008 42.0796 80 44.3192 80 48.8V71.2C80 75.6804 80.0008 77.9208 79.1288 79.632C78.362 81.1376 77.1364 82.3608 75.6312 83.128C73.92 84 71.6812 84 67.2008 84H28.8008C24.3204 84 22.0785 84 20.3672 83.128C18.8619 82.3608 17.6389 81.1376 16.872 79.632C16 77.9208 16 75.6804 16 71.2ZM36 35.0769V24C36 17.3726 41.3724 12 48 12C54.6276 12 60 17.3726 60 24V35.0769C60 35.5867 59.5856 36 59.0756 36H36.9219C36.4121 36 36 35.5867 36 35.0769Z"
      stroke={stroke}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
