/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ErrorCodes = {
    TechnicalError: 'TECHNICAL_ERROR',
    RequestValidationError: 'REQUEST_VALIDATION_ERROR',
    EntityNotFound: 'ENTITY_NOT_FOUND',
    ForbiddenAccess: 'FORBIDDEN_ACCESS',
    UnauthorizedAccess: 'UNAUTHORIZED_ACCESS',
    DisplaynameAlreadyExists: 'DISPLAYNAME_ALREADY_EXISTS',
    RequirementsNotMeet: 'REQUIREMENTS_NOT_MEET',
    ChannelIsAlreadyVerified: 'CHANNEL_IS_ALREADY_VERIFIED',
    UserIsBannedFromChat: 'USER_IS_BANNED_FROM_CHAT',
    OnlyOnePinnedMessageAllowed: 'ONLY_ONE_PINNED_MESSAGE_ALLOWED',
    UserAlreadyModerator: 'USER_ALREADY_MODERATOR',
    UserNotAuthorizedToSeePost: 'USER_NOT_AUTHORIZED_TO_SEE_POST',
    PostNotFound: 'POST_NOT_FOUND'
} as const;
export type ErrorCodes = typeof ErrorCodes[keyof typeof ErrorCodes];


export function ErrorCodesFromJSON(json: any): ErrorCodes {
    return ErrorCodesFromJSONTyped(json, false);
}

export function ErrorCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorCodes {
    return json as ErrorCodes;
}

export function ErrorCodesToJSON(value?: ErrorCodes | null): any {
    return value as any;
}

