import React from 'react'
import { useChannelContext } from '../../context/channel-context'
import { LoadingScreen } from '../../../components/indicators/LoadingScreen'
import { SmartChannelPosts } from './SmartChannelPosts'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../../../styles/token.stylex'
import { tabStyles } from '../tabStyles'
import { RestrictedMedia } from '../../../post/restricted-media/RestrictedMedia'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  channelPosts: {
    marginTop: {
      default: spacing.small,
      [largeScreen]: spacing.large,
    },
  },
})

export const ChannelPostsPage: React.FC = () => {
  const { channel, isLoading } = useChannelContext()

  if (isLoading) return <LoadingScreen />

  if (!channel) return null

  const showPostsInFeed = channel.restrictions.showPostsInChannelFeed

  return (
    <div {...props(tabStyles.contentContainer, styles.channelPosts)}>
      {!showPostsInFeed && <RestrictedMedia channel={channel} />}
      {showPostsInFeed && <SmartChannelPosts channel={channel} />}
    </div>
  )
}
