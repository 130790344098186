import { create, props } from '@stylexjs/stylex'
import {
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
} from '../../client'
import { color, spacing } from '../../styles/token.stylex'
import { UnlockableItem } from './UnlockableItem'
import { Stats } from '../../stats/Stats'
import { SmartSubscribeButton } from '../../subscription/SmartSubscribeButton'
import useLanguage from '../../translations/useLanguage'

type RestrictedMediaProps = {
  channel: GetChannelInfoResponse
  me?: GetChannelLoggedInUserResponse
}

const styles = create({
  container: {
    color: color.unlockedCardTextColor,
    backgroundColor: color.unlockedCardBackgroundColor,
    borderRadius: '.5rem',
    height: '400px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: spacing.normal,
  },
  text: {
    display: 'flex',
    gap: spacing.xxSmall,
  },
  price: {
    color: color.unlockedCardPriceColor,
  },
})

export const RestrictedMedia: React.FC<RestrictedMediaProps> = ({
  channel,
  me,
}) => {
  const { t } = useLanguage()

  return (
    <div>
      <div {...props(styles.container)}>
        <UnlockableItem iconSize="xLarge">
          <Stats stats={{ ...channel.stats, likesCount: undefined }} />
          <SmartSubscribeButton
            channel={channel}
            me={me}
            text={t('subscription.subscribeToSeeUsersPosts')}
          />
        </UnlockableItem>
      </div>
    </div>
  )
}
