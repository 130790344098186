import React from 'react'
import { ChannelContextProvider } from '../channel/context/ChannelContextProvider'
import { useUserContext } from '../auth/user-context'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { SmartCreatePostPage } from './create/SmartCreatePost'

export const CreatePostPage: React.FC = () => {
  const { data, isLoading } = useUserContext()

  if (isLoading || !data) return <LoadingScreen />

  return (
    <ChannelContextProvider slug={data.streamerChannel.slug}>
      <SmartCreatePostPage />
    </ChannelContextProvider>
  )
}
