import type { SVGProps } from 'react'

export const Stream: React.FC<SVGProps<SVGElement>> = ({
  stroke = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    height={height}
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_2989838"
    fill={stroke}
  >
    <g id="b85db83d-9203-44f9-8716-5a4a8d73faeb" data-name="Layer 11">
      <path d="m11.57 23a1 1 0 0 1 -.68-.27 9.28 9.28 0 0 1 0-13.46 1 1 0 0 1 1.36 1.46 7.3 7.3 0 0 0 0 10.54 1 1 0 0 1 -.68 1.73zm-4 2.68a1 1 0 0 0 0-1.41 11.43 11.43 0 0 1 0-16.54 1 1 0 1 0 -1.41-1.46 13.44 13.44 0 0 0 0 19.46 1 1 0 0 0 1.42-.05zm13.53-2.95a9.28 9.28 0 0 0 0-13.46 1 1 0 1 0 -1.37 1.46 7.27 7.27 0 0 1 0 10.54 1 1 0 0 0 -.05 1.41 1 1 0 0 0 .74.32 1 1 0 0 0 .68-.27zm4.72 3a13.41 13.41 0 0 0 0-19.46 1 1 0 0 0 -1.36 1.46 11.43 11.43 0 0 1 0 16.54 1 1 0 0 0 -.05 1.41 1 1 0 0 0 .73.32 1 1 0 0 0 .68-.27zm-7.82-9.73a2 2 0 1 0 -2 2 2 2 0 0 0 2-2z"></path>
    </g>
  </svg>
)
