import { useMemo, useState } from 'react'
import { useApi } from '../../api/ApiContext'
import useLanguage, {
  SUPPORTED_LANGUAGES,
} from '../../translations/useLanguage'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import {
  DepositRequest,
  DepositResponse,
  GetPostResponse,
  PaymentType,
} from '../../client'
import { queryKeys } from '../../constants/query-keys'
import {
  computeVatDetails,
  getDefaultPaymentMethod,
  getPaymentMethodOptions,
} from '../../post/tip/utils'
import { create, props } from '@stylexjs/stylex'
import { Button } from '../../components/Button'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { ReactSelect } from '../../components/input/Select'
import { color, spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'
import { sharedStyles } from '../../common/styles'
import { routes } from '../../router/routes'

type PpvModalProps = {
  post: GetPostResponse
}

type PpvFormValues = {
  paymentMethod: string
}

export const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '320px',
    position: 'relative',
  },
  actionsContainer: {
    display: 'flex',
    ':not(#_id) button': {
      width: '100%',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
  },
  hidden: {
    display: 'none',
  },
  priceDetailed: {
    color: color.dustyGray,
  },
})

export const PpvModalContent: React.FC<PpvModalProps> = ({ post }) => {
  const { t, formatCurrency, languageCode } = useLanguage()
  const { paymentsApi } = useApi()
  const [isLoading, setIsLoading] = useState(false)

  const { control, handleSubmit, setValue, watch, formState } =
    useForm<PpvFormValues>({
      defaultValues: { paymentMethod: '' },
      mode: 'onChange',
    })

  const { mutateAsync } = useMutation<DepositResponse, Error, DepositRequest>({
    mutationFn: async (requestParameters: DepositRequest) => {
      return paymentsApi.paymentsPayPost({ depositRequest: requestParameters })
    },
  })

  const { data, isFetching } = useQuery({
    queryKey: [queryKeys.payments.default, post.purchase.price.amount],
    queryFn: () =>
      paymentsApi.paymentsMethodsVatGet({
        type: PaymentType.Ppv,
        price: Math.round(post.purchase.price.amount),
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: post.purchase.price.amount > 0,
    onSuccess: (fetchedData) => {
      if (fetchedData.paymentMethods?.length && !watch('paymentMethod')) {
        const defaultMethod = getDefaultPaymentMethod(fetchedData)
        setValue('paymentMethod', defaultMethod, { shouldValidate: true })
      }
    },
  })

  const { vatAmount, totalAmount } = useMemo(() => {
    return computeVatDetails(
      data,
      watch('paymentMethod'),
      Number(post.purchase.price.amount)
    )
  }, [data, post.purchase.price.amount, watch])

  const onSubmit = async (formData: PpvFormValues) => {
    setIsLoading(true)
    try {
      const language = SUPPORTED_LANGUAGES.find(
        (lang) => lang.code === languageCode
      )
      if (!language) {
        console.error('Language not found:', languageCode)
        setIsLoading(false)
        return
      }

      const redirectUrl =
        window.location.origin +
        routes.channel.postById(post.postId, post.author.slug)

      const requestBody: DepositRequest = {
        language: language.cultureCode,
        depositMethod: formData.paymentMethod,
        redirectUrl: redirectUrl,
        currency: post.purchase.price.currency,
        paymentType: PaymentType.Ppv,
        recipientUserId: post.author.id,
        postId: post.postId,
      } as DepositRequest

      const response = await mutateAsync(requestBody)
      const paymentUrl = response?.paymentPageSession?.paymentPageUrl

      if (!paymentUrl) {
        console.error('Payment URL not found in response.')
        setIsLoading(false)
        return
      }

      window.location.href = paymentUrl
    } catch (error) {
      console.error('Payment submission failed:', error)
      setIsLoading(false)
    }
  }

  const paymentMethodOptions = data
    ? getPaymentMethodOptions(data.paymentMethods, watch('paymentMethod'), t)
    : []

  return (
    <div {...props(styles.container)}>
      <form onSubmit={handleSubmit(onSubmit)} {...props(styles.formContainer)}>
        <div>
          <p {...props(fonts.smallSemiBold)}>
            {t('ppv.chargeMessage', {
              amount: formatCurrency(totalAmount, {
                currency: post.purchase.price.currency,
              }),
            })}
          </p>
          <span {...props(fonts.smallRegular, styles.priceDetailed)}>
            {t('ppv.priceBreakdown', {
              basePrice: formatCurrency(post.purchase.price.amount, {
                currency: post.purchase.price.currency,
              }),
              vatAmount: formatCurrency(vatAmount, {
                currency: post.purchase.price.currency,
              }),
            })}
          </span>
        </div>

        <Controller
          name="paymentMethod"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              required
              id="ppv-payment-method-select"
              label={t('shared.general.paymentMethod')}
              placeholder={t('shared.general.paymentMethodPlaceholder')}
              options={paymentMethodOptions}
              value={field.value}
            />
          )}
        />
        <div {...props(styles.actionsContainer)}>
          <Button
            type="submit"
            variant="cta"
            disabled={!formState.isValid || isFetching}
          >
            {t('shared.general.pay')}
          </Button>
        </div>
      </form>
      <div {...props(!isLoading ? styles.hidden : sharedStyles.loading)}>
        <LoadingIndicator width={40} height={40} />
      </div>
    </div>
  )
}
