import { create } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'

export const styles = create({
  container: {
    display: 'flex',
    maxWidth: '888px',
    margin: '0 auto',
    width: '100%',
    ':not(_id) > div': {
      width: '100%',
    },
  },
  header: {
    padding: spacing.normal,
  },
  content: {
    paddingBottom: 0,
  },
  dropzoneArea: {
    overflowX: 'visible',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    paddingBottom: 0,
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  dragActive: {
    border: `1px solid ${color.gold}`,
  },
  previewWrapper: {
    display: 'flex',
    gap: spacing.small,
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    marginBottom: spacing.small,
    paddingBottom: spacing.small,
    '::-webkit-scrollbar': { height: '8px' },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  hidden: {
    display: 'none',
  },
  previewItem: {
    position: 'relative',
    width: '130px',
    height: '130px',
    flex: '0 0 130px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: color.mineShaft,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  addButton: {
    display: 'flex',
    flex: '0 0 60px',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .15s',
    backgroundColor: {
      default: color.mineShaft,
      ':hover': color.mineShaftDark,
    },
    borderRadius: '8px',
    cursor: 'pointer',
    color: {
      default: '#888',
      ':hover': color.gold,
    },
  },
  actionButtonsWrapper: {
    borderTop: `1px solid ${color.dustyGray}`,
    padding: spacing.normal,
    display: 'flex',
    alignItems: 'center',
    gap: spacing.normal,
    ':not(#__id) button': {
      width: 'fit-content',
      height: 'fit-content',
    },
  },
  paid: {
    borderTopColor: color.gold,
  },
  priceTag: {
    ':not(#__id) button': {
      borderRadius: '16px',
      padding: `${spacing.xSmall} ${spacing.small}`,
    },
  },
  textAreaWrapper: {
    ':not(#__id) fieldset': {
      border: 'none !important',
    },
    ':not(#__id) textarea': {
      padding: '10px 16px',
    },
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '5px',
    backgroundColor: '#e0e0e0',
    borderRadius: '0 0 4px 4px',
  },
  progress: {
    height: '100%',
    backgroundColor: color.gold,
    borderRadius: '0 0 4px 4px',
  },
  edditButton: {
    position: 'absolute',
    bottom: 8,
    right: 8,
  },
  deleteButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
})
