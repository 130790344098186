/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPurchaseConfigurationResponse
 */
export interface GetPurchaseConfigurationResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseConfigurationResponse
     */
    minAmount: number;
    /**
     * 
     * @type {number}
     * @memberof GetPurchaseConfigurationResponse
     */
    maxAmount: number;
}

/**
 * Check if a given object implements the GetPurchaseConfigurationResponse interface.
 */
export function instanceOfGetPurchaseConfigurationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minAmount" in value;
    isInstance = isInstance && "maxAmount" in value;

    return isInstance;
}

export function GetPurchaseConfigurationResponseFromJSON(json: any): GetPurchaseConfigurationResponse {
    return GetPurchaseConfigurationResponseFromJSONTyped(json, false);
}

export function GetPurchaseConfigurationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPurchaseConfigurationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minAmount': json['minAmount'],
        'maxAmount': json['maxAmount'],
    };
}

export function GetPurchaseConfigurationResponseToJSON(value?: GetPurchaseConfigurationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minAmount': value.minAmount,
        'maxAmount': value.maxAmount,
    };
}

