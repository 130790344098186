import { Navigate, Outlet } from 'react-router-dom'
import { routes } from './routes'
import { useUserContext } from '../auth/user-context'

export const CreatorRoute: React.FC = () => {
  const { isContentCreator } = useUserContext()

  return isContentCreator ? (
    <Outlet />
  ) : (
    <Navigate to={routes.becomeACreator} replace />
  )
}
