import { useState, forwardRef } from 'react'
import { create, props } from '@stylexjs/stylex'
import { fonts } from '../../styles/font-styles'
import { inputStyles } from './styles'
import { InputDefaultProps } from './types'
import { InputPanel } from './InputPanel'
import { Eye } from '../icons/Eye'
import { EyeClosed } from '../icons/EyeClosed'
import { IconButton } from '../IconButton'
import { color } from '../../styles/token.stylex'

const styles = create({
  prefixed: {
    borderRadius: `0 .5rem .5rem 0`,
  },
  passwordWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    right: '0.5rem',
    zIndex: 2,
  },
  password: {
    paddingRight: '3.5rem',
  },
  input: {
    width: '100%',
  },
  number: {
    '::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
  prefixWrapper: {
    color: color.dustyGray,
    ':focus-within': {
      color: color.white,
    },
  },
  prefixLabel: {
    position: 'absolute',
    left: '1rem',
    zIndex: 2,
    pointerEvents: 'none',
  },
  prefixedInput: {
    paddingLeft: '2.125rem',
  },
  postfixedInput: {
    paddingRight: '3rem',
  },
})

type InputProps = {
  variant?: 'prefixed'
  secondInstruction?: string
  prefix?: string
  postfix?: React.ReactNode
} & InputDefaultProps<HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      value,
      label,
      placeholder,
      disabled,
      instruction,
      error,
      required,
      onChange,
      variant,
      readOnly = false,
      onClick,
      secondInstruction,
      type,
      prefix,
      postfix,
      maxLength,
      autoFocus,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false)
    const isPassword = type === 'password'

    return (
      <InputPanel
        id={id}
        error={error}
        label={label}
        required={required}
        instruction={instruction}
        secondInstruction={secondInstruction}
      >
        <div
          {...props(styles.passwordWrapper, !!prefix && styles.prefixWrapper)}
        >
          {!!prefix && <span {...props(styles.prefixLabel)}>{prefix}</span>}

          <input
            ref={ref}
            {...props(
              inputStyles.container,
              styles.input,
              fonts.smallRegular,
              !!error && inputStyles.error,
              variant && styles[variant],
              type && styles[type],
              !!prefix && styles.prefixedInput,
              !!postfix && styles.postfixedInput
            )}
            id={id}
            spellCheck={false}
            onChange={onChange}
            onClick={onClick}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            type={showPassword ? 'text' : type}
            readOnly={readOnly}
            maxLength={maxLength}
            autoFocus={autoFocus}
          />
          {postfix ?? postfix}
          {isPassword && (
            <div {...props(styles.iconWrapper)}>
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                icon={showPassword ? EyeClosed : Eye}
              />
            </div>
          )}
        </div>
      </InputPanel>
    )
  }
)

Input.displayName = 'Input'
