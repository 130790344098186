import type { SVGProps } from 'react'

export const ArrowLeft: React.FC<SVGProps<SVGElement>> = ({
  fill = 'currentColor',
  height = '1.5rem',
  width = '1.5rem',
}) => (
  <svg
    id="fi_3114883"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path d="m22 11h-17.586l5.293-5.293a1 1 0 1 0 -1.414-1.414l-7 7a1 1 0 0 0 0 1.414l7 7a1 1 0 0 0 1.414-1.414l-5.293-5.293h17.586a1 1 0 0 0 0-2z"></path>
  </svg>
)
