import { useAuth0 } from '@auth0/auth0-react'
import { useSearchParams } from 'react-router-dom'

export default function LoginPage() {
  const { loginWithRedirect } = useAuth0()

  const [searchParams] = useSearchParams()
  const returnUrl = searchParams.get('returnUrl')

  loginWithRedirect(
    returnUrl
      ? {
          appState: { returnTo: returnUrl },
        }
      : undefined
  )

  return <></>
}
