import React from 'react'
import { SmartPromoPanelEditor } from './SmartPromoPanelEditor'
import { useChannelContext } from '../../context/channel-context'
import { LoadingScreen } from '../../../components/indicators/LoadingScreen'
import { create, props } from '@stylexjs/stylex'
import { tabStyles } from '../tabStyles'
import { spacing } from '../../../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  promoPanels: {
    marginTop: {
      default: spacing.small,
      [largeScreen]: spacing.large,
    },
  },
})

export const ChannelAboutPage: React.FC = () => {
  const { channel, isLoading } = useChannelContext()

  if (isLoading) return <LoadingScreen />

  if (!channel) return null

  return (
    <div {...props(tabStyles.contentContainer, styles.promoPanels)}>
      <SmartPromoPanelEditor channel={channel} />
    </div>
  )
}
