import { SinglePostPage } from './SinglePostPage'
import { LoadingScreen } from '../components/indicators/LoadingScreen'
import { useChannelContext } from '../channel/context/channel-context'
import useLanguage from '../translations/useLanguage'
import { Navigate } from 'react-router-dom'
import { routes } from '../router/routes'

type SmartSinglePostProps = {
  postId: string
}

export const SmartSinglePost: React.FC<SmartSinglePostProps> = ({ postId }) => {
  const { channel, isLoading, me } = useChannelContext()
  const { t } = useLanguage()

  if (isLoading) return <LoadingScreen />

  if (!channel) return t('singleStream.channelNotCreated')

  const isSubscriber = !!me?.subscription
  const isSystemUserPost = !!channel.user.isSystemUser
  const isChannelOwner = !!me?.isChannelOwner

  if (!isSubscriber && !isSystemUserPost && !isChannelOwner)
    return <Navigate to={routes.channel.homeShort(channel.slug)} replace />

  return <SinglePostPage postId={postId} channelSlug={channel.slug} />
}
