import { create, props } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'
import { DisplayName } from './DisplayName'
import { AvatarImage } from './avatars/AvatarImage'
import { Creator } from './types'

const styles = create({
  creatorInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
  },
})

type CreatorInfoProps = Creator & { maxNameWidth?: string }

export const CreatorInfo: React.FC<CreatorInfoProps> = ({
  profilePictureUrl,
  displayName,
  isVerified,
  avatarImageSize,
  profileUrl,
  maxNameWidth,
}) => (
  <div {...props(styles.creatorInfo)}>
    <AvatarImage
      src={profilePictureUrl}
      alt={`${displayName}'s profile`}
      variant={avatarImageSize}
      url={profileUrl}
    />
    <DisplayName
      text={displayName}
      verification={isVerified ? 'simple' : undefined}
      font="smallMedium"
      url={profileUrl}
      maxWidth={maxNameWidth}
    />
  </div>
)
