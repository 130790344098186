import { createContext, useContext } from 'react'
import { GetUserInfoResponse } from '../client'

type UserContextProps = {
  isLoading: boolean
  isError: boolean
  data?: GetUserInfoResponse
  isContentCreator: boolean
  isSystemUser: boolean
  reloadUser: () => void
}

export const UserContext = createContext<UserContextProps>({
  isLoading: false,
  isError: false,
  isContentCreator: false,
  isSystemUser: false,
  reloadUser: () => {},
})

export const useUserContext = () => useContext(UserContext)
