import { ValidatedFile } from '../../common/utils/fileValidationUtils'

export const filterValidAssetIds = (files: ValidatedFile[]) =>
  files
    .filter((file) => file.isUploaded && file.assetId)
    .map((file) => file.assetId!)

export const canSetPaidPost = (
  isFreeToSubscribe: boolean,
  postPrice: number | null
) => {
  if (!postPrice) return true
  return isFreeToSubscribe
}

export const isPostValid = (
  postContent: string,
  files: ValidatedFile[],
  postPrice: number | null,
  isFreeToSubscribe: boolean
) => {
  const hasContent = postContent.trim() !== '' || files.length > 0
  const isPaidValid = canSetPaidPost(isFreeToSubscribe, postPrice)

  return hasContent && isPaidValid
}

export const processCroppedImage = async (
  croppedImageUrl: string,
  originalFile: ValidatedFile,
  updateFileDataById: (id: string, updatedFile: ValidatedFile) => void,
  uploadFile: (file: ValidatedFile) => void
): Promise<void> => {
  try {
    const response = await fetch(croppedImageUrl)
    const blob = await response.blob()

    const croppedFile = new File([blob], originalFile.file.name, {
      type: originalFile.file.type,
    })

    const croppedImage = new Image()
    croppedImage.src = croppedImageUrl

    croppedImage.onload = () => {
      const { width, height } = croppedImage

      const updatedFile: ValidatedFile = {
        ...originalFile,
        file: croppedFile,
        url: croppedImageUrl,
        dimensions: { width, height },
        isUploaded: false,
        assetId: undefined,
      }

      updateFileDataById(originalFile.id, updatedFile)
      uploadFile(updatedFile)
    }
  } catch (error) {
    console.error('Failed to process cropped image:', error)
  }
}

export const getCroppedImg = async (
  imageSrc: string,
  crop: { x: number; y: number; width: number; height: number },
  zoom: number = 1
): Promise<string> => {
  const image = new Image()
  image.src = imageSrc

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const scaleX = image.width / image.naturalWidth
      const scaleY = image.height / image.naturalHeight

      canvas.width = crop.width * zoom
      canvas.height = crop.height * zoom

      if (ctx) {
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          canvas.width,
          canvas.height
        )
      }

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(URL.createObjectURL(blob))
          } else {
            reject(new Error('Canvas is empty'))
          }
        },
        'image/jpeg',
        1
      )
    }
    image.onerror = reject
  })
}
