import { routes } from '../../router/routes'
import { props } from '@stylexjs/stylex'
import { fonts, titleFonts } from '../../styles/font-styles'
import useLanguage from '../../translations/useLanguage'
import { errorPageStyles } from './error-page-styles'
import { TextLink } from '../../components/TextLink'

const NotFoundPage: React.FC = () => {
  const { t } = useLanguage()

  return (
    <div {...props(errorPageStyles.container)}>
      <div {...props(titleFonts.errorSemiBold)}>{404}</div>
      <div {...props(fonts.normalSemiBold)}>
        {t('pageNotFound.title')}
        <br /> {t('pageNotFound.message')}
      </div>
      <div {...props(errorPageStyles.linkContainer)}>
        <TextLink to={routes.home}>{t('pageNotFound.goToHomePage')}</TextLink>
      </div>
    </div>
  )
}

export default NotFoundPage
