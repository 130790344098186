import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { ChangeEventHandler } from 'react'
import { fonts } from '../styles/font-styles'

const styles = create({
  radio: {
    appearance: 'none',
    accentColor: color.primary,
    width: '1rem',
    height: '1rem',
    minHeight: '1rem',
    minWidth: '1rem',
    borderRadius: '50%',
    border: `1px solid ${color.primary}`,
    backgroundColor: 'transparent',
    backgroundClip: 'content-box',
    cursor: 'pointer',
    ':checked': {
      backgroundColor: color.primary,
      padding: '0.215rem',
    },
    ':hover:not(:checked)': {
      borderWidth: '2px',
    },
    ':disabled': {
      borderColor: color.radioButtonBorderDisabled,
      cursor: 'default',
      color: color.radioButtonDisabled,
      ':checked': {
        backgroundColor: color.radioButtonDisabled,
      },
      ':hover': {
        borderWidth: '1px',
      },
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
    color: color.radioButtonLabelDefault,
    cursor: 'pointer',
    ':has(input:checked)': {
      color: color.radioButtonLabelSelected,
    },
    ':has(input:disabled)': {
      cursor: 'default',
      color: color.radioButtonLabelDisabled,
    },
  },
})

type RadioButtonProps = {
  label: string
  id: string
  value: string
  selectedValue: string
  disabled?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  id,
  value,
  selectedValue,
  disabled,
  onChange,
}) => (
  <label htmlFor={id} {...props(styles.label, fonts.normalMedium)}>
    <input
      type="radio"
      id={id}
      value={value}
      disabled={!!disabled}
      onChange={onChange}
      {...props(styles.radio)}
      checked={selectedValue === value}
    />
    {label}
  </label>
)
