import { useAuth0 } from '@auth0/auth0-react'
import useLanguage from '../translations/useLanguage'

export default function SignUpPage() {
  const { loginWithRedirect } = useAuth0()
  const { languageCode } = useLanguage()

  loginWithRedirect({
    authorizationParams: {
      screen_hint: 'signup',
      ui_locales: languageCode,
    },
  })

  return <></>
}
