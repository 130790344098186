/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface BaseUserResponse
 */
export interface BaseUserResponse {
    /**
     * 
     * @type {number}
     * @memberof BaseUserResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    displayName: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof BaseUserResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUserResponse
     */
    canChangeDisplayName?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUserResponse
     */
    isSystemUser: boolean;
}

/**
 * Check if a given object implements the BaseUserResponse interface.
 */
export function instanceOfBaseUserResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "isSystemUser" in value;

    return isInstance;
}

export function BaseUserResponseFromJSON(json: any): BaseUserResponse {
    return BaseUserResponseFromJSONTyped(json, false);
}

export function BaseUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'canChangeDisplayName': !exists(json, 'canChangeDisplayName') ? undefined : json['canChangeDisplayName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isSystemUser': json['isSystemUser'],
    };
}

export function BaseUserResponseToJSON(value?: BaseUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'canChangeDisplayName': value.canChangeDisplayName,
        'email': value.email,
        'isSystemUser': value.isSystemUser,
    };
}

