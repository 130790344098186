import React, { FC } from 'react'
import { Warning } from '../../components/icons/Warning'
import { LoadingIndicator } from '../../components/indicators/LoadingIndicator'
import { props } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'
import useLanguage from '../../translations/useLanguage'
import { FieldValues, FormState } from 'react-hook-form'
import { GetPaymentMethodsVatResponse } from '../../client'
import { styles } from './TipStyles'

type PostfixProps = {
  formState: FormState<FieldValues>
  isFetching: boolean
  data: GetPaymentMethodsVatResponse | undefined
  vatAmount: number
  totalAmount: number
}

export const Postfix: FC<PostfixProps> = ({
  formState,
  isFetching,
  data,
  vatAmount,
  totalAmount,
}) => {
  const { t, formatCurrency } = useLanguage()

  const renderVatDisplay = (content: React.ReactNode) => (
    <div {...props(styles.vatDisplay)}>{content}</div>
  )

  if (!formState.isValid) {
    return renderVatDisplay(
      <Warning fill={color.error} width={18} height={18} />
    )
  }

  if (isFetching && data) {
    return renderVatDisplay(<LoadingIndicator width={18} height={18} />)
  }

  const vatText = data
    ? `+ ${formatCurrency(Math.round(vatAmount), { currency: 'JPY' })} ${t('tip.vat')}`
    : 'N/A'

  const totalAmountText = data
    ? formatCurrency(Math.round(totalAmount), { currency: 'JPY' })
    : '...'

  return renderVatDisplay(
    <>
      <span {...props(styles.vatText)}>{vatText}</span>
      <span {...props(styles.totalText)}>
        <span
          {...props(styles.totalTextLabel)}
        >{`${t('tip.totalTextLabel')}:`}</span>
        <span {...props(styles.totalTextAmount)}>{totalAmountText}</span>
      </span>
    </>
  )
}
