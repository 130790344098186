import { create } from '@stylexjs/stylex'
import { color } from '../styles/token.stylex'

export const sharedStyles = create({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'calc(100% + 5px)',
    height: 'calc(100% + 5px)',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    backgroundColor: color.codGrayLight,
  },
})
