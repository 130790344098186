import { LanguageKey } from '../../translations/useLanguage'

export const MAXIMUM_PRICE = 7500
export const MINIMUM_PRICE = 500

export type PriceData = {
  price: string
}

export const priceValidator = (
  data: PriceData,
  translate: (
    key: LanguageKey,
    replacements?: Record<string, string>
  ) => string,
  formatCurrency: (amount: number, options: { currency: string }) => string
) => {
  const price = parseInt(data.price)
  // Subscription price can be 0
  if (isNaN(price) || price === 0) return { values: data, errors: {} }

  if (price > MAXIMUM_PRICE) {
    return {
      values: data,
      errors: {
        price: {
          message: translate('subscription.maximumPrice', {
            maximumPrice: formatCurrency(MAXIMUM_PRICE, { currency: 'JPY' }),
          }),
        },
      },
    }
  }
  if (price < MINIMUM_PRICE) {
    return {
      values: data,
      errors: {
        price: {
          message: translate('subscription.minimumPrice', {
            minimumPrice: formatCurrency(MINIMUM_PRICE, { currency: 'JPY' }),
          }),
        },
      },
    }
  }
  return { values: data, errors: {} }
}

export const expirationDate = new Date()
expirationDate.setMonth(expirationDate.getMonth() + 1)
