import React, { useState, useEffect } from 'react'
import { useApi } from '../../api/ApiContext'
import useLanguage from '../../translations/useLanguage'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { create, props } from '@stylexjs/stylex'
import { Button } from '../../components/Button'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  title: {
    marginBottom: '1rem',
  },
  description: {
    marginBottom: '1.5rem',
    textAlign: 'justify',
  },
  buttonWrapper: {
    paddingTop: '1rem',
    display: 'flex',
    gap: '20px',
    justifyContent: 'center',
    flexDirection: {
      default: 'column',
      [largeScreen]: 'row',
    },
  },
})

const EmailVerificationPrompt: React.FC = () => {
  const { t } = useLanguage()
  const { auth0Api } = useApi()

  const [cooldown, setCooldown] = useState(false)
  const [timeLeft, setTimeLeft] = useState(60)

  const { mutate: resendVerificationEmail, isLoading } = useMutation(
    async () => {
      return auth0Api.auth0ResendVerificationEmailPost()
    },
    {
      onSuccess: () => {
        toast.success(t('accountRelated.verifyEmailResendSuccess'))
        setCooldown(true)
      },
      onError: () => {
        toast.error(t('error.emailVerificationResendFailed'))
      },
    }
  )

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (cooldown) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer)
            setCooldown(false)
            setTimeLeft(60)
            return 60
          }
          return prevTime - 1
        })
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [cooldown])

  return (
    <div {...props(styles.container)}>
      <h2 {...props(styles.title)}>{t('accountRelated.verifyEmailTitle')}</h2>
      <p {...props(styles.description)}>
        {t('accountRelated.verifyEmailMessage')}
      </p>
      <div {...props(styles.buttonWrapper)}>
        <Button
          disabled={cooldown || isLoading}
          variant="cta"
          onClick={() => resendVerificationEmail()}
          isLoading={isLoading}
        >
          {cooldown
            ? `${t('accountRelated.resendVerificationEmail')} (${timeLeft}s)`
            : t('accountRelated.resendVerificationEmail')}
        </Button>
      </div>
    </div>
  )
}

export default EmailVerificationPrompt
