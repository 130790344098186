export type Unlockable = {
  price: Money
  payment?: Money
  isPaid: boolean
  onUnlock: () => void
  text?: string
  showUnlockButton?: boolean
}

export type Money = {
  amount: number
  currency: string
}

export const nonNullable = <T>(
  value: T | null | undefined
): value is NonNullable<T> => !!value
