/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetChannelLoggedInUserResponse,
  GetSubscriptionResponsePagedApiResponse,
} from '../models/index';
import {
    GetChannelLoggedInUserResponseFromJSON,
    GetChannelLoggedInUserResponseToJSON,
    GetSubscriptionResponsePagedApiResponseFromJSON,
    GetSubscriptionResponsePagedApiResponseToJSON,
} from '../models/index';

export interface SubscriptionsGetRequest {
    pageNumber?: number;
    pageSize?: number;
}

export interface SubscriptionsSubscribeChannelIdPostRequest {
    channelId: string;
}

/**
 * 
 */
export class SubscriptionsApi extends runtime.BaseAPI {

    /**
     */
    async subscriptionsGetRaw(requestParameters: SubscriptionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSubscriptionResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSubscriptionResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     */
    async subscriptionsGet(requestParameters: SubscriptionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSubscriptionResponsePagedApiResponse> {
        const response = await this.subscriptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async subscriptionsSubscribeChannelIdPostRaw(requestParameters: SubscriptionsSubscribeChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelLoggedInUserResponse>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling subscriptionsSubscribeChannelIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/subscriptions/subscribe/{channelId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelLoggedInUserResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async subscriptionsSubscribeChannelIdPost(requestParameters: SubscriptionsSubscribeChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelLoggedInUserResponse> {
        const response = await this.subscriptionsSubscribeChannelIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
