/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetUserInfoResponse,
  UpdateDescriptionRequest,
  UpdateDisplayNameRequest,
  UpdateSocialUrlsRequest,
  UpdateSubscribePriceRequest,
  UploadBannerPictureResponse,
  UploadProfilePictureResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetUserInfoResponseFromJSON,
    GetUserInfoResponseToJSON,
    UpdateDescriptionRequestFromJSON,
    UpdateDescriptionRequestToJSON,
    UpdateDisplayNameRequestFromJSON,
    UpdateDisplayNameRequestToJSON,
    UpdateSocialUrlsRequestFromJSON,
    UpdateSocialUrlsRequestToJSON,
    UpdateSubscribePriceRequestFromJSON,
    UpdateSubscribePriceRequestToJSON,
    UploadBannerPictureResponseFromJSON,
    UploadBannerPictureResponseToJSON,
    UploadProfilePictureResponseFromJSON,
    UploadProfilePictureResponseToJSON,
} from '../models/index';

export interface UserChannelDescriptionPutRequest {
    updateDescriptionRequest?: UpdateDescriptionRequest;
}

export interface UserDisplaynamePutRequest {
    updateDisplayNameRequest?: UpdateDisplayNameRequest;
}

export interface UserSocialurlsPutRequest {
    updateSocialUrlsRequest?: UpdateSocialUrlsRequest;
}

export interface UserSubscribepricePutRequest {
    updateSubscribePriceRequest?: UpdateSubscribePriceRequest;
}

export interface UserUploadChannelBannerpicturePutRequest {
    image?: Blob;
}

export interface UserUploadProfilepicturePutRequest {
    image?: Blob;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Updates description for channel
     */
    async userChannelDescriptionPutRaw(requestParameters: UserChannelDescriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/user/channel/description`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDescriptionRequestToJSON(requestParameters.updateDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates description for channel
     */
    async userChannelDescriptionPut(requestParameters: UserChannelDescriptionPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userChannelDescriptionPutRaw(requestParameters, initOverrides);
    }

    /**
     * Updates display name for user.
     */
    async userDisplaynamePutRaw(requestParameters: UserDisplaynamePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/user/displayname`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDisplayNameRequestToJSON(requestParameters.updateDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates display name for user.
     */
    async userDisplaynamePut(requestParameters: UserDisplaynamePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDisplaynamePutRaw(requestParameters, initOverrides);
    }

    /**
     * Gets user information for the logged in user
     */
    async userGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets user information for the logged in user
     */
    async userGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserInfoResponse> {
        const response = await this.userGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates social urls for user
     */
    async userSocialurlsPutRaw(requestParameters: UserSocialurlsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/user/socialurls`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSocialUrlsRequestToJSON(requestParameters.updateSocialUrlsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates social urls for user
     */
    async userSocialurlsPut(requestParameters: UserSocialurlsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSocialurlsPutRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the subscription price for the user.
     */
    async userSubscribepricePutRaw(requestParameters: UserSubscribepricePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/user/subscribeprice`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSubscribePriceRequestToJSON(requestParameters.updateSubscribePriceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the subscription price for the user.
     */
    async userSubscribepricePut(requestParameters: UserSubscribepricePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSubscribepricePutRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads banner picture for channel
     */
    async userUploadChannelBannerpicturePutRaw(requestParameters: UserUploadChannelBannerpicturePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadBannerPictureResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/user/upload/channel/bannerpicture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadBannerPictureResponseFromJSON(jsonValue));
    }

    /**
     * Uploads banner picture for channel
     */
    async userUploadChannelBannerpicturePut(requestParameters: UserUploadChannelBannerpicturePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadBannerPictureResponse> {
        const response = await this.userUploadChannelBannerpicturePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads profile picture for user
     */
    async userUploadProfilepicturePutRaw(requestParameters: UserUploadProfilepicturePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadProfilePictureResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/user/upload/profilepicture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadProfilePictureResponseFromJSON(jsonValue));
    }

    /**
     * Uploads profile picture for user
     */
    async userUploadProfilepicturePut(requestParameters: UserUploadProfilepicturePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadProfilePictureResponse> {
        const response = await this.userUploadProfilepicturePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
