import { create, props } from '@stylexjs/stylex'
import { SmartSubscribeButton } from './SmartSubscribeButton'
import { spacing } from '../styles/token.stylex'
import {
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
} from '../client'
import { SubscriptionExpiration } from './SubscriptionExpiration'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: {
      default: spacing.small,
      [largeScreen]: spacing.normal,
    },
  },
})

type SubscriptionProps = {
  channel: GetChannelInfoResponse
  me?: GetChannelLoggedInUserResponse
}

export const SmartSubscription: React.FC<SubscriptionProps> = ({
  channel,
  me,
}) => {
  if (!channel) return <></>

  return (
    <div {...props(styles.container)}>
      <SmartSubscribeButton channel={channel} me={me} />
      <SubscriptionExpiration me={me} />
    </div>
  )
}
