import React from 'react'
import EmailVerificationPrompt from './EmailVerificationPrompt'
import useLanguage from '../../translations/useLanguage'
import { TextLink } from '../../components/TextLink'
import { create, props } from '@stylexjs/stylex'
import { color } from '../../styles/token.stylex'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    backdropFilter: 'blur(5px)',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: {
      default: 'flex-start',
      [largeScreen]: 'center',
    },
    zIndex: 999,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    boxShadow: `0 4px 8px ${color.black}`,
  },
  rightColumn: {
    background: color.black,
    padding: '0.5rem',
    flex: '1 0 50%',
    display: {
      default: 'none',
      [largeScreen]: 'flex',
    },
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
  },
  contentWrapper: {
    background: color.codGrayLight,
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: 'unset',
    [largeScreen]: {
      width: '80%',
      height: '80%',
      maxHeight: '700px',
      maxWidth: '1300px',
    },
  },
  leftColumn: {
    padding: '2rem',
  },
  rightContent: {
    paddingInline: '1rem',
  },
})

const CompleteRegistration: React.FC = () => {
  const { t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.contentWrapper)}>
        <div {...props(styles.leftColumn)}>
          <EmailVerificationPrompt />
        </div>
        <div {...props(styles.rightColumn)}>
          <div {...props(styles.rightContent)}>
            {t('accountRelated.tagline')}
            <TextLink
              font="normalMedium"
              isExternal
              to="https://app.clickup.com/t/86c0wzznu"
            >
              {'86c0wzznu'}
            </TextLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompleteRegistration
