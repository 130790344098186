import { create, props } from '@stylexjs/stylex'
import { Button } from '../components/Button'
import useLanguage from '../translations/useLanguage'
import {
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
} from '../client'
import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../api/ApiContext'
import { queryKeys } from '../constants/query-keys'
import { useNavigate } from 'react-router-dom'
import { routes } from '../router/routes'
import { ConfirmDialog } from '../components/confirm-dialog/ConfirmDialog'
import { useRef } from 'react'
import { useUserContext } from '../auth/user-context'
import { Dialog } from '../components/dialog/Dialog'
import { PaidSubscriptionModalContent } from './subscriptions/PaidSubscriptionModalContent'
import { useSubscriptionState } from './useSubscriptionState'

type SmartSubscribeButtonProps = {
  channel: GetChannelInfoResponse
  me?: GetChannelLoggedInUserResponse
  text?: string
}

const styles = create({
  subscribe: {
    width: '100%',
    ':not(#_id) button': {
      width: '100%',
    },
  },
})

export const SmartSubscribeButton: React.FC<SmartSubscribeButtonProps> = ({
  channel,
  me,
  text,
}) => {
  const { formatCurrency, t } = useLanguage()
  const { data: currentUser } = useUserContext()
  const { subscriptionsApi } = useApi()
  const confirmDialogRef = useRef<HTMLDialogElement>(null)
  const paidSubscriptionDialogRef = useRef<HTMLDialogElement>(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { isSubscribed, isFreeSubscription, formattedPrice } =
    useSubscriptionState(channel, me)

  const { mutateAsync: toggleSubscription, isLoading } = useMutation({
    mutationFn: async (channelId: string) =>
      await subscriptionsApi.subscriptionsSubscribeChannelIdPost({
        channelId,
      }),
    onSuccess: (newMeData) => {
      queryClient.setQueryData(queryKeys.channel.me(channel.slug), newMeData)
      queryClient.invalidateQueries(queryKeys.channel.slug(channel.slug))
    },
    onSettled: () => {
      confirmDialogRef.current?.close()
    },
  })

  const getButtonText = () => {
    if (text) return text
    if (channel.isChannelOwner) return t('subscription.options.edit')
    if (!isSubscribed)
      return formattedPrice
        ? t('subscription.options.paid.subscribe', { price: formattedPrice })
        : t('subscription.options.free.subscribe')

    return isFreeSubscription
      ? t('subscription.options.free.subscribed')
      : t('subscription.options.paid.subscribed', {
          price: formatCurrency(
            channel.subscriptionOptions.subscribePrice.amount
          ),
        })
  }

  const handleSubscriptionAction = () => {
    if (!currentUser) {
      navigate(
        `${routes.login}?returnUrl=${encodeURIComponent(location.pathname + location.search)}`
      )
      return
    }

    if (channel.isChannelOwner) {
      navigate(routes.profileRoutes.subscriptionPrice)
      return
    }

    if (isSubscribed) {
      confirmDialogRef.current?.showModal()
    } else if (formattedPrice) {
      paidSubscriptionDialogRef.current?.showModal()
    } else {
      toggleSubscription(channel.id)
    }
  }

  return (
    <>
      <div {...props(styles.subscribe)}>
        <Button
          onClick={handleSubscriptionAction}
          disabled={isLoading || (isSubscribed && !isFreeSubscription)}
          variant={!isSubscribed ? 'cta' : undefined}
        >
          {getButtonText()}
        </Button>
        <ConfirmDialog
          ref={confirmDialogRef}
          options={{
            title: t('subscription.options.cancel.title'),
            description: t('subscription.options.cancel.message'),
            onConfirm: () => toggleSubscription(channel.id),
            isLoading,
            translate: t,
          }}
        />
      </div>
      {currentUser && !isFreeSubscription && !isSubscribed && (
        <div>
          <Dialog
            ref={paidSubscriptionDialogRef}
            title={t('subscription.confirmTitle')}
            showCloseButton={true}
            cancel={{
              label: t('shared.buttons.cancel'),
              action: () => {
                paidSubscriptionDialogRef.current?.close()
              },
            }}
            closeOnOutsideClick={false}
          >
            <PaidSubscriptionModalContent channel={channel} />
          </Dialog>
        </div>
      )}
    </>
  )
}
