import { Unlockable } from '../../common/types'
import { Button } from '../../components/Button'
import useLanguage from '../../translations/useLanguage'

type UnlockButtonProps = {
  unlockable?: Unlockable
}

export const UnlockButton: React.FC<UnlockButtonProps> = ({ unlockable }) => {
  const { formatCurrency, t } = useLanguage()

  if (!unlockable || !unlockable.showUnlockButton) return <></>

  const { onUnlock, price } = unlockable

  const formattedPrice = formatCurrency(price.amount, {
    currency: price.currency,
  })

  return (
    <Button onClick={onUnlock} variant="cta">
      {t('post.postCard.unlockButtonText', {
        price: formattedPrice,
      })}
    </Button>
  )
}
