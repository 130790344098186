import { PaymentMethodResponse } from '../../client'
import { Wallet } from '../../components/icons/Wallet'
import { Option } from '../../components/input/Select'
import { ResolverResult } from 'react-hook-form'
import { LanguageKey } from '../../translations/useLanguage'

export const getPaymentMethodOptions = (
  paymentMethods: PaymentMethodResponse[],
  defaultMethod: string,
  translate: (key: LanguageKey, replacements?: Record<string, string>) => string
): Option[] => {
  return paymentMethods.map((paymentMethod) => ({
    label: translate(`tip.methods.${paymentMethod.method}`),
    value: paymentMethod.method,
    icon: paymentMethod.method === defaultMethod ? Wallet : undefined,
    active: paymentMethod.method === defaultMethod,
  }))
}

export const computeVatDetails = (
  data: { paymentMethods: PaymentMethodResponse[] } | undefined,
  selectedMethod: string,
  tipPrice: number
) => {
  if (!data) return { vatAmount: 0, totalAmount: 0 }

  const payment = data.paymentMethods.find((pm) => pm.method === selectedMethod)
  const vatAmount = payment?.vatPrice ?? 0
  const totalAmount = tipPrice + vatAmount
  return { vatAmount, totalAmount }
}

export const getDefaultPaymentMethod = (
  data: { paymentMethods: PaymentMethodResponse[] } | undefined
) => {
  if (!data || data.paymentMethods.length === 0) return ''
  return data.paymentMethods[0].method
}

type TipData = { price: string }

export function tipValidator(
  data: TipData,
  translate: (
    key: LanguageKey,
    replacements?: Record<string, string>
  ) => string,
  formatCurrency: (amount: number, options: { currency: string }) => string,
  minimumPrice: number,
  maximumPrice: number
): ResolverResult<TipData> {
  const price = parseInt(data.price)

  if (price > maximumPrice) {
    return {
      values: data,
      errors: {
        price: {
          message: translate('tip.maximumPrice', {
            maximumPrice: formatCurrency(maximumPrice, { currency: 'JPY' }),
          }),
        },
      },
    }
  }
  if (price < minimumPrice || isNaN(price)) {
    return {
      values: data,
      errors: {
        price: {
          message: translate('tip.minimumPrice', {
            minimumPrice: formatCurrency(minimumPrice, { currency: 'JPY' }),
          }),
        },
      },
    }
  }
  return { values: data, errors: {} }
}

export const sanitizeInput = (value: string, maxLength: number): string => {
  const sanitizedValue = value.replace(/[eE+-.]/g, '')
  return sanitizedValue.slice(0, maxLength)
}

export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>
): void => {
  if (['e', 'E', '+', '-'].includes(event.key)) {
    event.preventDefault()
  }
}
