import { useState, useEffect } from 'react'
import {
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
} from '../client'
import useLanguage from '../translations/useLanguage'

export const useSubscriptionState = (
  channel: GetChannelInfoResponse,
  me?: GetChannelLoggedInUserResponse
) => {
  const [isSubscribed, setIsSubscribed] = useState(!!me?.subscription)
  const [isFreeSubscription, setIsFreeSubscription] = useState(false)
  const [formattedPrice, setFormattedPrice] = useState<string | undefined>()
  const { formatCurrency } = useLanguage()

  useEffect(() => {
    if (!channel?.subscriptionOptions) return
    const subscriptionPrice = channel.subscriptionOptions.subscribePrice.amount
    const isFree = subscriptionPrice === 0

    setIsSubscribed(!!me?.subscription)
    setIsFreeSubscription(isFree)

    if (!isFree) {
      setFormattedPrice(
        formatCurrency(subscriptionPrice, {
          currency: channel.subscriptionOptions.subscribePrice.currency,
        })
      )
    } else {
      setFormattedPrice(undefined)
    }
  }, [channel, formatCurrency, me])

  return { isSubscribed, isFreeSubscription, formattedPrice, setIsSubscribed }
}
