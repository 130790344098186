/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetMoneyResponse } from './GetMoneyResponse';
import {
    GetMoneyResponseFromJSON,
    GetMoneyResponseFromJSONTyped,
    GetMoneyResponseToJSON,
} from './GetMoneyResponse';

/**
 * 
 * @export
 * @interface GetSubscriptionOptionsResponse
 */
export interface GetSubscriptionOptionsResponse {
    /**
     * 
     * @type {GetMoneyResponse}
     * @memberof GetSubscriptionOptionsResponse
     */
    subscribePrice: GetMoneyResponse;
    /**
     * Indicates whether new users can currently subscribe to this content creator.
     * @type {boolean}
     * @memberof GetSubscriptionOptionsResponse
     */
    canAddSubscriber: boolean;
}

/**
 * Check if a given object implements the GetSubscriptionOptionsResponse interface.
 */
export function instanceOfGetSubscriptionOptionsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subscribePrice" in value;
    isInstance = isInstance && "canAddSubscriber" in value;

    return isInstance;
}

export function GetSubscriptionOptionsResponseFromJSON(json: any): GetSubscriptionOptionsResponse {
    return GetSubscriptionOptionsResponseFromJSONTyped(json, false);
}

export function GetSubscriptionOptionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSubscriptionOptionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscribePrice': GetMoneyResponseFromJSON(json['subscribePrice']),
        'canAddSubscriber': json['canAddSubscriber'],
    };
}

export function GetSubscriptionOptionsResponseToJSON(value?: GetSubscriptionOptionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscribePrice': GetMoneyResponseToJSON(value.subscribePrice),
        'canAddSubscriber': value.canAddSubscriber,
    };
}

