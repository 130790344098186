import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../../styles/token.stylex'
import { ThinLock } from '../../components/icons/ThinLock'

const styles = create({
  container: {
    color: color.unlockableItemColor,
    backgroundColor: color.unlockableItemBackground,
    borderRadius: spacing.small,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: spacing.large,
    padding: spacing.larger,
  },
})

type UnlockableItemProps = {
  iconSize?: IconSizeVariant
}

type IconSizeVariant = 'small' | 'large' | 'xLarge'

const sizes: Record<IconSizeVariant, { width: string; height: string }> = {
  small: { width: '1.75rem', height: '1.75rem' },
  large: { width: '3.25rem', height: '3.25rem' },
  xLarge: { width: '6rem', height: '6rem' },
}

export const UnlockableItem: React.FC<
  React.PropsWithChildren<UnlockableItemProps>
> = ({ children, iconSize = 'small' }) => {
  const { width, height } = sizes[iconSize]

  return (
    <div {...props(styles.container)}>
      {<ThinLock stroke={color.lightGray} width={width} height={height} />}
      {children}
    </div>
  )
}
