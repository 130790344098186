import { create, props } from '@stylexjs/stylex'
import { color, spacing } from '../styles/token.stylex'
import { UnlockButton } from './restricted-media/UnlockButton'
import React, { PropsWithChildren } from 'react'
import { ContextMenuItem, Creator } from '../components/types'
import { CreatorInfo } from '../components/CreatorInfo'
import { ContextMenu } from '../components/ContextMenu'
import { PostActions } from './PostActions'
import { ContextMenuDots } from '../components/icons/ContextMenuDots'
import { IconButton } from '../components/IconButton'
import { fonts } from '../styles/font-styles'
import useLanguage, {
  FormatCurrencyOptions,
  LanguageKey,
} from '../translations/useLanguage'
import { ExpandableText } from '../components/ExpandableText'
import { TextLink } from '../components/TextLink'
import { Unlockable } from '../common/types'
import { Lock } from '../components/icons/Lock'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
    padding: spacing.normal,
    borderRadius: spacing.extraSmall,
    backgroundColor: color.deepGray,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightTop: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing.small,
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacing.xxSmall,
    color: color.dustyGray,
  },
  actions: {
    paddingTop: spacing.normal,
  },
})

type PostCardProps = {
  creator: Creator
  text: React.ReactNode
  publishedAt: Date
  onLike?: () => void
  likesCount?: number
  isLiked?: boolean
  likeInProgress?: boolean
  onComment?: () => void
  onTip?: () => void
  unlockable?: Unlockable
  contextMenuItems?: ContextMenuItem[]
  isLoading?: boolean
  postUrl: string
  showFooter?: boolean
}

const getUnlockablePrice = (
  unlockable: Unlockable | undefined,
  formatCurrency: (amount: number, options: FormatCurrencyOptions) => string,
  t: (key: LanguageKey, replacements?: Record<string, string>) => string
) => {
  if (!unlockable) return null

  const price = unlockable.isPaid
    ? t('post.paidPost.purchasedFor', {
        price: formatCurrency(unlockable.payment?.amount ?? 0, {
          currency: unlockable.payment?.currency ?? 'JPY',
        }),
      })
    : formatCurrency(unlockable.price.amount, {
        currency: unlockable.price.currency,
      })

  return (
    <div {...props(styles.price, fonts.smallRegular)}>
      {price} <Lock width="1.125rem" height="1.125rem" />
    </div>
  )
}

export const PostCard: React.FC<PropsWithChildren<PostCardProps>> = ({
  creator,
  text,
  publishedAt,
  onLike,
  onComment,
  onTip,
  likesCount,
  likeInProgress,
  isLiked,
  unlockable,
  contextMenuItems,
  children,
  postUrl,
  showFooter = true,
}) => {
  const { formatCurrency, formatDateRelativeToday, t } = useLanguage()

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.header)}>
        <CreatorInfo {...creator} profileUrl={creator.profileUrl} />
        <div {...props(styles.headerRight)}>
          <div {...props(styles.rightTop)}>
            <TextLink variant="gray" underline to={postUrl}>
              <span {...props(fonts.smallRegular)}>
                {formatDateRelativeToday(publishedAt)}
              </span>
            </TextLink>
            {!!contextMenuItems?.length && (
              <ContextMenu items={contextMenuItems}>
                <IconButton icon={ContextMenuDots} variant="large" />
              </ContextMenu>
            )}
          </div>
          {getUnlockablePrice(unlockable, formatCurrency, t)}
        </div>
      </div>
      <div {...props(styles.content)}>
        <ExpandableText text={text} maxLines={3} />
      </div>
      {children}
      <UnlockButton unlockable={unlockable} />
      {showFooter && (
        <div {...props(styles.actions)}>
          <PostActions
            onLike={onLike}
            likeInProgress={likeInProgress ?? false}
            likesCount={likesCount ?? 0}
            isLiked={isLiked ?? false}
            onComment={onComment}
            onTip={onTip}
          />
        </div>
      )}
    </div>
  )
}
