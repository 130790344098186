import { create, props } from '@stylexjs/stylex'
import { IconButton } from '../../components/IconButton'
import useLanguage, {
  SUPPORTED_LANGUAGES,
} from '../../translations/useLanguage'
import { spacing } from '../../styles/token.stylex'
import { fonts } from '../../styles/font-styles'

const styles = create({
  container: {
    display: 'flex',
    gap: spacing.small,
    cursor: 'pointer',
  },
  flagContainer: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const LanguageSelector: React.FC = () => {
  const { languageCode, setLanguageCode } = useLanguage()

  return (
    <div {...props(styles.container)}>
      {SUPPORTED_LANGUAGES.map((lang) => {
        if (lang.code === languageCode) {
          return null
        }

        return (
          <div
            key={lang.code}
            onClick={() => setLanguageCode(lang.code)}
            {...props(styles.flagContainer)}
          >
            <IconButton icon={lang.icon}></IconButton>
            <span {...props(fonts.extraSmallSemiBold)}>{lang.name}</span>
          </div>
        )
      })}
    </div>
  )
}
