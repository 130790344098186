import { create, props } from '@stylexjs/stylex'
import { forwardRef } from 'react'
import { color, spacing } from '../../styles/token.stylex'
import { IconButton } from '../IconButton'
import { Close } from '../icons/Close'
import { Button } from '../Button'
import { fonts, titleFonts } from '../../styles/font-styles'
import { DialogAction } from './types'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  dialog: {
    margin: 'auto',
    border: `1px solid ${color.dialogBorder}`,
    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: spacing.extraSmall,
    backgroundColor: color.dialogBackground,
    color: color.dialogDefaultText,
    overflow: 'hidden',
    zIndex: 1000,
    '::backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: {
      default: spacing.small,
      [largeScreen]: `${spacing.large} ${spacing.large} ${spacing.extraLarge} ${spacing.large}`,
    },
  },
  header: {
    paddingBottom: spacing.large,
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: spacing.normal,
  },
  rowStart: {
    alignItems: 'flex-start',
  },
  description: {
    marginTop: spacing.large,
    marginBottom: spacing.xxl,
  },
  fullWidthButton: {
    width: '100%',
    ':not(#_id) button': {
      width: {
        default: '100%',
      },
    },
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing.normal,
    width: '100%',
  },
})

type DialogProps = {
  title?: string
  description?: React.ReactNode
  ok?: DialogAction
  cancel: DialogAction
  variant?: 'dual' | 'single'
  showCloseButton?: boolean
  closeOnOutsideClick?: boolean
}

const DialogInternal = (
  {
    title,
    description,
    ok,
    cancel,
    variant = 'dual',
    showCloseButton = true,
    closeOnOutsideClick = true,
    children,
  }: React.PropsWithChildren<DialogProps>,
  ref: React.Ref<HTMLDialogElement>
) => (
  <dialog
    ref={ref}
    {...props(styles.dialog)}
    onClick={(e) =>
      closeOnOutsideClick && e.target === e.currentTarget && cancel.action()
    }
    onCancel={cancel.action}
  >
    <div {...props(styles.container)}>
      {(title || showCloseButton) && (
        <div {...props(styles.row, styles.rowStart, styles.header)}>
          {title && <h1 {...props(titleFonts.normalMedium)}>{title}</h1>}
          {showCloseButton && (
            <IconButton icon={Close} variant="large" onClick={cancel.action} />
          )}
        </div>
      )}
      {description && (
        <p {...props(styles.description, fonts.smallRegular)}>{description}</p>
      )}
      <div {...props(styles.row)}>
        {children}
        {!children && variant === 'dual' && ok && (
          <div {...props(styles.dualButtonContainer)}>
            <Button onClick={cancel.action} disabled={cancel.disabled}>
              {cancel.label}
            </Button>
            <Button
              variant="cta"
              onClick={ok.action}
              isLoading={ok.isLoading}
              disabled={ok.disabled || ok.isLoading}
            >
              {ok.label}
            </Button>
          </div>
        )}
        {!children && variant === 'single' && ok && (
          <div {...props(styles.fullWidthButton)}>
            <Button
              variant="cta"
              onClick={ok.action}
              disabled={ok.disabled || ok.isLoading}
              isLoading={ok.isLoading}
            >
              {ok.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  </dialog>
)

export const Dialog = forwardRef(DialogInternal)
