import { create } from '@stylexjs/stylex'
import {
  spacing,
  color,
  fontSize,
  lineHeight,
  fontWeight,
} from '../../styles/token.stylex'
import { StylesConfig } from 'react-select'
import { Option } from './Select'

export const inputStyles = create({
  container: {
    border: 0,
    padding: `${spacing.extraSmall} ${spacing.small}`,
    borderRadius: '.5rem',
    caretColor: color.inputOutlineFocus,
    backgroundColor: {
      default: color.inputBackgroundDefault,
    },
    color: {
      default: color.inputColorDefault,
      ':focus': color.inputColorActive,
    },
    outline: {
      default: `1px solid ${color.inputColorDefault}`,
      ':hover:enabled:not(:focus)': `1px solid ${color.inputOutlineHover}`,
      ':focus': `1px solid ${color.inputOutlineFocus}`,
      ':disabled': `1px solid ${color.inputBackgroundDisabled}`,
    },
  },
  error: {
    outline: `1px solid ${color.error}`,
  },
})

export const mentionsStyles = create({
  input: {
    resize: 'none',
    width: '100%',
    fontSize: '14px',
    lineHeight: '1.5',
    padding: '8px',
    border: 'none',
    borderRadius: '4px',
    outline: 'none',
    maxHeight: 350,
    minHeight: 43,
    ':not(#_id) ul': {
      padding: `${spacing.extraSmall} !important`,
      borderRadius: '4px',
    },
    ':not(#_id) ul li:hover span': {
      color: color.gold,
    },
    ':not(#_id) div:has(> ul)': {
      backgroundColor: `${color.mineShaft} !important`,
      borderRadius: '.5rem',
      marginTop: `${spacing.large} !important`,
      zIndex: '999 !important',
    },
    ':not(#_id) textarea': {
      height: '100%',
      resize: 'none',
      outline: 'none',
      overflow: 'visible !important',
      border: 'none',
      color: 'white',
      '::-webkit-scrollbar': { height: '8px', width: '8px' },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',
      },
    },
  },
})

const getOptionBackgroundColor = ({
  isSelected,
  isFocused,
}: {
  isSelected: boolean
  isFocused: boolean
}) => {
  if (isSelected) return color.primary
  if (isFocused) return color.mineShaft
  return 'transparent'
}

export const customSelectStyles: StylesConfig<Option, false> = {
  control: (provided) => ({
    ...provided,
    border: `1px solid ${color.inputColorDefault}`,
    padding: `0 ${spacing.xxxSmall}`,
    borderRadius: '.375rem',
    caretColor: color.codGrayLight,
    boxShadow: 'none',
    backgroundColor: color.inputBackgroundDefault,
    color: color.inputColorActive,
    ':hover': {
      borderColor: color.inputOutlineHover,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: color.inputColorActive,
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.regular,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: color.inputColorActive,
    ':hover': {
      color: color.gold,
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: color.codGrayLight,
    zIndex: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: getOptionBackgroundColor(state),
    fontSize: fontSize.small,
    lineHeight: lineHeight.small,
    fontWeight: fontWeight.regular,
  }),
}
