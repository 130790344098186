import useLanguage from '../translations/useLanguage'
import { useAuth0 } from '@auth0/auth0-react'
import { Profile } from '../components/icons/Profile'
import { IconButton } from '../components/IconButton'

export const PhoneLoginButton: React.FC = () => {
  const { languageCode } = useLanguage()
  const { loginWithRedirect } = useAuth0()

  const profileIcon = () => <Profile width="1.25rem" height="1.25rem" />

  const handleAuth = () =>
    loginWithRedirect({
      authorizationParams: {
        ui_locales: languageCode,
      },
      appState: { returnTo: window.location.pathname + window.location.search },
    })

  return <IconButton icon={profileIcon} onClick={handleAuth} />
}
