import { useState } from 'react'
import { toast } from 'react-toastify'
import useLanguage, { LanguageKey } from '../translations/useLanguage'
import { useApi } from '../api/ApiContext'
import { ReportReason, ReportType } from '../client'
import { Button } from '../components/Button'
import { RadioButton } from '../components/RadioButton'
import { useMutation } from 'react-query'
import { useErrorNotification } from '../translations/useErrorNotification'
import { create, props } from '@stylexjs/stylex'
import { spacing } from '../styles/token.stylex'

const REASONS = [
  'violenceOrHarmfulBehaviour',
  'bullyingOrHarassment',
  'hateSpeechOrSymbols',
  'miscategorizedContent',
  'selfHarm',
  'nudityOrSexualActivity',
  'spamScamFraudPhishingOrImpersonation',
  'intellectualPropertyViolation',
  'terrorism',
  'childEndangerment',
]

const REASONS_MAP: { [key: string]: ReportReason } = {
  violenceOrHarmfulBehaviour: ReportReason.ViolenceOrHarmfulBehaviour,
  bullyingOrHarassment: ReportReason.BullyingOrHarassment,
  hateSpeechOrSymbols: ReportReason.HateSpeechOrSymbols,
  miscategorizedContent: ReportReason.MiscategorizedContent,
  selfHarm: ReportReason.SelfHarm,
  nudityOrSexualActivity: ReportReason.NudityOrSexualActivity,
  spamScamFraudPhishingOrImpersonation:
    ReportReason.SpamScamFraudPhishingOrImpersonation,
  intellectualPropertyViolation: ReportReason.IntellectualPropertyViolation,
  terrorism: ReportReason.Terrorism,
  childEndangerment: ReportReason.ChildEndangerment,
}

type ReportingModalProps = {
  onClose: () => void
  resourceId: string
  reportType: ReportType
}

const styles = create({
  options: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.small,
    paddingBottom: spacing.normal,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: spacing.normal,
  },
})

export const ReportingModalContent: React.FC<ReportingModalProps> = ({
  onClose,
  resourceId,
  reportType,
}: ReportingModalProps) => {
  const { t } = useLanguage()
  const { reportApi } = useApi()
  const [selectedReason, setSelectedReason] = useState<string>('')
  const notifyError = useErrorNotification()

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const mappedReason = REASONS_MAP[selectedReason]
      if (!mappedReason) throw new Error('Invalid reason')

      await reportApi.contentreportPost({
        reportRequest: {
          reason: mappedReason,
          resourceIdentifier: resourceId,
          reportType: reportType,
        },
      })
    },
    onSuccess: () => {
      toast.success(t('reporting.reportSuccess'))
    },
    onError: notifyError(() => {
      toast.error(t('reporting.reportFailed'))
    }),
    onSettled: () => {
      onClose()
      setSelectedReason('')
    },
  })

  const handleSubmit = () => {
    mutate()
  }

  const handleClose = () => {
    setSelectedReason('')
    onClose()
  }

  return (
    <div>
      <div {...props(styles.options)}>
        {REASONS.map((reason) => (
          <RadioButton
            id={reason}
            key={reason}
            label={t(`reporting.reasons.${reason}` as LanguageKey)}
            value={reason}
            onChange={() => setSelectedReason(reason)}
            selectedValue={selectedReason}
          />
        ))}
      </div>
      <div {...props(styles.actionsContainer)}>
        <Button onClick={() => handleClose()}>
          {t('shared.buttons.cancel')}
        </Button>
        <Button
          variant="cta"
          disabled={!selectedReason || isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {t('reporting.report')}
        </Button>
      </div>
    </div>
  )
}
