import { useParams } from 'react-router-dom'
import { ChannelContextProvider } from './context/ChannelContextProvider'
import { SmartChannel } from './SmartChannel'

export const ChannelPage: React.FC = () => {
  const { slug } = useParams()

  if (!slug) throw new Error('Slug not found')

  return (
    <ChannelContextProvider slug={slug}>
      <SmartChannel />
    </ChannelContextProvider>
  )
}
