import { create, props } from '@stylexjs/stylex'
import { color, elementSizes, spacing } from '../../styles/token.stylex'
import { Link } from '../../components/Link'
import { fonts } from '../../styles/font-styles'
import { FullCreatorInfo } from '../../components/FullCreatorInfo'
import useLanguage, { LanguageKey } from '../../translations/useLanguage'
import { FullCreator } from '../../components/types'
import { useUnderConstructionDialog } from '../../style-guide/components/under-construction/under-construction-context'
import { MenuItem } from './types'
import { responsiveStyles } from '../navbar/styles'
import { PageHeader } from '../PageHeader'
import { Button } from '../../components/Button' // adjust the path if needed
import { routes } from '../../router/routes'
import { useNavigate } from 'react-router-dom'

const largeScreen = '@media (min-width: 768px)'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: elementSizes.sidebarWidth,
    height: `calc(100dvh - ${elementSizes.headerHeight})`,
    padding: spacing.normal,
    backgroundColor: color.codGray,
  },
  separator: {
    margin: `${spacing.extraSmall} 0 ${spacing.extraSmall} 0`,
    height: '1px',
    backgroundColor: color.mineShaft,
  },
  footer: {
    flex: 1,
    alignContent: 'flex-end',
    paddingTop: spacing.large,
    paddingBottom: {
      default: elementSizes.footerHeight,
      [largeScreen]: 0,
    },
  },
  linkContent: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    paddingBottom: spacing.extraSmall,
  },
  header: {
    display: {
      default: 'flex',
      [largeScreen]: 'none',
    },
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing.xxSmall,
    paddingBottom: spacing.large,
  },
  profile: {
    width: '100%',
    height: `calc(100dvh - ${elementSizes.headerHeight} - ${elementSizes.footerHeight})`,
    maxHeight: '100%',
  },
  underConstructionContainer: {
    padding: `${spacing.xxxSmall} ${spacing.extraSmall}`,
    cursor: 'pointer',
    color: {
      default: color.white,
      ':hover': color.gold,
    },
  },
  menuTitle: {
    paddingBlock: `${spacing.small} ${spacing.large}`,
  },
  menuTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.extraSmall,
  },
  buttonContainer: {
    display: {
      default: 'none',
      [largeScreen]: 'flex',
    },
    flexDirection: 'column',
    marginTop: spacing.large,
    width: '100%',
  },
})

type MenuProps = {
  items: MenuItem[]
  footerItems?: MenuItem[]
  profile?: FullCreator
  variant?: 'profile'
}
const renderMenuItems = (
  items: MenuItem[],
  t: (key: LanguageKey) => string,
  openDialog: (clickupId: string) => void,
  navigate: (route: string) => void
) =>
  items.map(
    ({
      icon: Icon,
      labelKey,
      route,
      isExternal,
      hasSeparator,
      isCreatorButton,
    }) => {
      if (isCreatorButton) {
        return (
          <div key={labelKey} {...props(styles.buttonContainer)}>
            <Button onClick={() => navigate(routes.post.create)} variant="cta">
              <Icon /> {t(labelKey)}
            </Button>
          </div>
        )
      }
      return (
        <div key={labelKey}>
          <Link to={route} isExternal={isExternal}>
            <div {...props(styles.linkContent)}>
              {<Icon width="1.25rem" height="1.25rem" />}
              <div {...props(fonts.largeRegular)}>{t(labelKey)}</div>
            </div>
          </Link>
          {hasSeparator && <div {...props(styles.separator)} />}
        </div>
      )
    }
  )

export const Menu: React.FC<MenuProps> = ({
  items,
  footerItems,
  profile,
  variant,
}) => {
  const { t } = useLanguage()
  const { openDialog } = useUnderConstructionDialog()
  const navigate = useNavigate()

  return (
    <>
      <div {...props(responsiveStyles.phoneFlex)}>
        <PageHeader>{t('menu.title').toUpperCase()}</PageHeader>
      </div>
      <nav {...props(styles.container, variant && styles[variant])}>
        {profile && (
          <div {...props(responsiveStyles.phoneFlex, styles.header)}>
            <FullCreatorInfo
              displayName={profile.displayName}
              isVerified={profile.isVerified}
              profilePictureUrl={profile.profilePictureUrl}
              avatarImageSize="medium"
              profileUrl={profile.profileUrl}
              fullName={profile.fullName}
            />
          </div>
        )}
        {renderMenuItems(items, t, openDialog, navigate)}
        {footerItems && (
          <div {...props(styles.footer)}>
            {renderMenuItems(footerItems, t, openDialog, navigate)}
          </div>
        )}
      </nav>
    </>
  )
}
