import { toast } from 'react-toastify'
import useLanguage from '../../translations/useLanguage'

export type FileValidationOptions = {
  maxSizeMB?: number
  minWidth?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
  allowedTypes?: string[]
}

export type FileValidator = {
  validateFile: (file: File, options: FileValidationOptions) => Promise<boolean>
}

export function useFileValidator(): FileValidator {
  const { t } = useLanguage()

  async function validateFileWithNotification(
    file: File,
    options: FileValidationOptions
  ) {
    const result = await validateFile(file, {
      maxSizeMB: options.maxSizeMB ?? 256,
      minWidth: options.minWidth ?? 0,
      minHeight: options.minHeight ?? 0,
      maxWidth: options.maxWidth ?? 9000,
      maxHeight: options.maxHeight ?? 9000,
      allowedTypes: options.allowedTypes ?? allowedImageTypes,
    })

    if (result === 'ok') return true

    toast.error(t(`fileUpload.${result}`))
    return false
  }
  return { validateFile: validateFileWithNotification }
}

async function validateFile(
  file: File,
  options: Required<FileValidationOptions>
) {
  if (!options.allowedTypes.includes(file.type)) {
    return 'unsupportedImageFormat'
  }

  if (file.size > options.maxSizeMB * 1024 * 1024) {
    return 'tooBigFileSize'
  }

  const shouldValidateImage =
    options.minWidth ||
    options.minHeight ||
    options.maxWidth ||
    options.maxHeight

  if (shouldValidateImage && file.type.startsWith('image/')) {
    return await validateImage(file, options)
  }

  return 'ok'
}

async function validateImage(
  file: File,
  options: Required<FileValidationOptions>
) {
  const img = await loadImage(file)

  const badMinWidth = img.width < options.minWidth
  const badMinHeight = img.height < options.minHeight
  const badMaxWidth = img.width > options.maxWidth
  const badMaxHeight = img.height > options.maxHeight

  if (badMinWidth || badMinHeight) return 'tooLowResolution'
  if (badMaxWidth || badMaxHeight) return 'tooHighResolution'
  return 'ok'
}

export function loadImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = () => {
      reject(new Error())
    }

    img.src = URL.createObjectURL(file)
  })
}

export const allowedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
]
