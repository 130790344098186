import React from 'react'
import { create, props } from '@stylexjs/stylex'
import { GetChannelPromoPanelResponse } from '../../../client'
import { spacing } from '../../../styles/token.stylex'
import { fonts, titleFonts } from '../../../styles/font-styles'

const largeScreen = '@media (min-width: 768px)'
const promoPanelDesktopWidth = '420px'

const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: spacing.small,
    maxWidth: {
      default: '100%',
      [largeScreen]: promoPanelDesktopWidth,
    },
  },
  setOrder: (order: number | undefined) => ({ order }),
  image: {
    width: {
      default: '100%',
      [largeScreen]: promoPanelDesktopWidth,
    },
  },
  description: {
    overflowWrap: 'break-word',
  },
})

type PromoPanelProps = {
  panel: GetChannelPromoPanelResponse
}

export const PromoPanel: React.FC<PromoPanelProps> = ({ panel }) => {
  const image = panel.image ? (
    <img
      {...props(styles.image)}
      src={panel.image.source}
      alt={panel.title || 'Promo Image'}
    />
  ) : null

  return (
    <div {...props(styles.container)}>
      <div {...props(styles.container, styles.setOrder(panel.order))}>
        {panel.title && (
          <div {...props(titleFonts.largeSemiBold)}>{panel.title}</div>
        )}
        {panel.imageLinkUrl && (
          <a target="_blank" href={panel.imageLinkUrl} rel="noreferrer">
            {image}
          </a>
        )}
        {!panel.imageLinkUrl && image}
        {panel.description && (
          <div {...props(fonts.smallRegular, styles.description)}>
            {panel.description}
          </div>
        )}
      </div>
    </div>
  )
}
