import React, { useEffect, useRef, useState } from 'react'
import {
  PromopanelPromoPanelsPanelIdPutRequest,
  PromopanelPromoPanelsPostRequest,
} from '../../../client'
import { Controller, useForm } from 'react-hook-form'
import { useFileValidator } from '../../../common/utils/useFileValidator'
import useLanguage from '../../../translations/useLanguage'
import { Input } from '../../../components/input/Input'
import { TextArea } from '../../../components/input/TextArea'
import { props } from '@stylexjs/stylex'
import { color } from '../../../styles/token.stylex'
import { Button } from '../../../components/Button'
import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api/ApiContext'
import { queryKeys } from '../../../constants/query-keys'
import { toast } from 'react-toastify'
import { ConfirmDialog } from '../../../components/confirm-dialog/ConfirmDialog'
import { Plus } from '../../../components/icons/Plus'
import { promoPanelEditorStyles } from './styles'
import { PromoPanelEditorProps, PromoPanelFields } from './types'
import { PreviewImage } from './PreviewImage'

const validateOptions = {
  maxSizeMB: 4,
  minHeight: 128,
  minWidth: 128,
  maxHeight: 3000,
  maxWidth: 3000,
}

export const PromoPanelEditor: React.FC<PromoPanelEditorProps> = ({
  type,
  panel,
  channelId,
  overrideEditing,
  allowDescription = false,
  allowTitle = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const confirmDialogRef = useRef<HTMLDialogElement>(null)
  const [isEditing, setIsEditing] = useState<boolean>(
    overrideEditing || type === 'edit'
  )

  const { control, handleSubmit, reset, setValue, formState } =
    useForm<PromoPanelFields>({
      defaultValues: {
        id: panel?.id,
        title: panel?.title ?? '',
        description: panel?.description ?? '',
        image: undefined,
        imageLinkUrl: panel?.imageLinkUrl ?? '',
      },
    })

  const [previewImageUrl, setPreviewImageUrl] = useState<string | undefined>(
    panel?.image?.source
  )

  const { validateFile } = useFileValidator()
  const { t } = useLanguage()
  const { promoPanelApi } = useApi()
  const queryClient = useQueryClient()

  const { mutateAsync: deleteMutation, isLoading: deleteIsLoading } =
    useMutation({
      mutationFn: (panelId: string) =>
        promoPanelApi.promopanelPromoPanelsPanelIdDelete({ panelId }),
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.promoPanel.default(channelId))
        toast.success(t('myChannel.promoPanel.editor.panelDeleted'))
      },
      onSettled: () => handleCancel(),
    })

  const { mutateAsync: updateMutation, isLoading: updateIsLoading } =
    useMutation({
      mutationFn: (data: PromopanelPromoPanelsPanelIdPutRequest) =>
        promoPanelApi.promopanelPromoPanelsPanelIdPut(data),
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeys.promoPanel.default(channelId))
        toast.success(t('myChannel.promoPanel.editor.panelUpdated'))

        const updatedFields: PromoPanelFields = {
          id: variables.panelId,
          title: variables.title ?? '',
          description: variables.description ?? '',
          imageLinkUrl: variables.imageLinkUrl ?? '',
        }

        reset(updatedFields)
      },
      onError: () => {
        handleCancel(false)
      },
    })

  const { mutateAsync: addMutation, isLoading: addIsLoading } = useMutation({
    mutationFn: (data: PromopanelPromoPanelsPostRequest) =>
      promoPanelApi.promopanelPromoPanelsPost(data),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.promoPanel.default(channelId))
      toast.success(t('myChannel.promoPanel.editor.panelAdded'))
      handleCancel()
      setIsEditing(false)
    },
  })

  const handleImageFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    const result = await validateFile(file, validateOptions)
    if (!result) return

    setValue('image', file, { shouldDirty: true })

    const reader = new FileReader()
    reader.onloadend = () => setPreviewImageUrl(reader.result as string)
    reader.readAsDataURL(file)
  }

  const handleCancel = (clearImage: boolean = true) => {
    reset()

    if (clearImage) {
      setPreviewImageUrl(undefined)
      if (fileInputRef.current?.value) fileInputRef.current.value = ''
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    const formattedData = {
      panelId: data.id,
      description: data.description,
      imageLinkUrl: data.imageLinkUrl,
      image: data.image,
      title: data.title,
    }

    const func = type === 'edit' ? updateMutation : addMutation
    func(formattedData)
  })

  useEffect(() => {
    if (overrideEditing) setIsEditing(true)
  }, [overrideEditing])

  const isLoading =
    formState.isSubmitting || addIsLoading || updateIsLoading || deleteIsLoading

  if (!isEditing) {
    return (
      <div
        {...props(promoPanelEditorStyles.empty)}
        onClick={() => setIsEditing(true)}
      >
        <Plus width="3rem" height="3rem" fill={color.torchRed} />
      </div>
    )
  }

  return (
    <>
      <form {...props(promoPanelEditorStyles.form)} onSubmit={onSubmit}>
        {allowTitle && (
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input
                label={t('myChannel.promoPanel.editor.panelTitle')}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}
        <div {...props(promoPanelEditorStyles.imageUploadContainer)}>
          <Button
            onClick={() => fileInputRef.current?.click()}
            variant="cta"
            type="button"
            disabled={isLoading}
          >
            {t('myChannel.promoPanel.editor.addImageButtonLabel')}
          </Button>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageFileChange}
            ref={fileInputRef}
            {...props(promoPanelEditorStyles.imageInput)}
          />
        </div>
        <PreviewImage
          previewImageUrl={previewImageUrl}
          onPreviewClick={() => fileInputRef.current?.click()}
        />
        <Controller
          control={control}
          name="imageLinkUrl"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              label={t('myChannel.promoPanel.editor.imageLinksTo')}
            />
          )}
        />
        {allowDescription && (
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextArea
                value={field.value}
                onChange={field.onChange}
                rows={3}
                maxLength={1000}
                label={t('myChannel.promoPanel.editor.description')}
              />
            )}
          />
        )}
        <div {...props(promoPanelEditorStyles.actions)}>
          <Button
            onClick={() =>
              type === 'edit'
                ? confirmDialogRef?.current?.showModal()
                : handleCancel()
            }
            disabled={isLoading}
            type="button"
          >
            {type === 'edit'
              ? t('myChannel.promoPanel.editor.removeButtonLabel')
              : t('myChannel.promoPanel.editor.cancelButtonLabel')}
          </Button>
          <Button
            variant="cta"
            type="submit"
            disabled={!formState.isDirty}
            isLoading={isLoading}
          >
            {type === 'edit'
              ? t('myChannel.promoPanel.editor.updateButtonLabel')
              : t('myChannel.promoPanel.editor.addButtonLabel')}
          </Button>
        </div>
      </form>
      {type === 'edit' && (
        <ConfirmDialog
          ref={confirmDialogRef}
          options={{
            title: t('myChannel.promoPanel.editor.deleteConfirmationTitle'),
            description: t(
              'myChannel.promoPanel.editor.deleteConfirmationText'
            ),
            closeOnConfirm: true,
            onConfirm: () => deleteMutation(panel.id),
            isLoading: deleteIsLoading,
            translate: t,
          }}
        />
      )}
    </>
  )
}
