import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { routes } from './routes'
import { useUserContext } from '../auth/user-context'

export const ProtectedRoute: React.FC = () => {
  const { data } = useUserContext()
  const location = useLocation()

  if (data) return <Outlet />

  const returnUrl = encodeURIComponent(location.pathname + location.search)

  return <Navigate to={`${routes.login}?returnUrl=${returnUrl}`} replace />
}
