import { create } from '@stylexjs/stylex'
import { spacing } from '../../../styles/token.stylex'

export const styles = create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '320px',
    position: 'relative',
  },
  actionsContainer: {
    display: 'flex',
    gap: spacing.extraSmall,
    justifyContent: 'flex-end',
  },
  warning: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  hidden: {
    display: 'none',
  },
})
